import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { observer } from "mobx-react-lite";
import { DraggableBlock } from "./DraggableBlock";

export const Droppable = observer(({ localStore, id }) => {
  const { setNodeRef } = useDroppable({
    id,
  });
  return (
    <SortableContext
      id={id}
      items={localStore.blocks}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} className="ls-blocks">
        {localStore.blocks.map((block) => (
          <DraggableBlock block={block} key={`block-${block.id}`} />
        ))}
      </div>
    </SortableContext>
  );
});
