import { Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";

export const NextBtn = observer(({ project, updateSettings }) => {
  const projectId = project.id;
  const projectPublicSettings = project.public_settings;

  const onSubmit = async (data) => {
    await updateSettings(projectId, data);
  };

  return (
    <GeneralEdit>
      <h4>Next Button</h4>
      <p>Lorem ipsum</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldText
                label="Next button text"
                finalFormProps={{
                  name: "nextBtn",
                  initialValue: projectPublicSettings.nextBtn,
                }}
                semanticProps={{
                  disabled: submitting,
                }}
              />
              <div className="ls-actions">
                <Button
                  basic
                  type="button"
                  disabled={submitting}
                  onClick={form.reset}
                >
                  cancel
                </Button>
                <Button
                  type="submit"
                  disabled={submitting}
                  loading={submitting}
                >
                  Apply
                </Button>
              </div>
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
