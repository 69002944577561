import { Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { FieldCheckbox } from "ui/FieldCheckbox";

export const Email = observer(({ project, updateSettings }) => {
  const projectId = project.id;
  const projectSettings = project.settings;

  const onSubmit = async (data) => {
    await updateSettings(projectId, data);
  };

  return (
    <GeneralEdit>
      <h4>Email notification</h4>
      <p>Lorem ipsum</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ul>
                <li>
                  <FieldCheckbox
                    finalFormProps={{
                      name: "use_reply_email",
                      initialValue: projectSettings.use_reply_email,
                    }}
                    semanticProps={{
                      label: "Send email",
                      disabled: submitting,
                    }}
                    initialChecked={projectSettings.use_reply_email}
                  />
                </li>
                {values.use_reply_email && (
                  <li>
                    <FieldText
                      label="Email"
                      finalFormProps={{
                        name: "reply_email",
                        initialValue: projectSettings.reply_email,
                      }}
                      semanticProps={{
                        placeholder: "example@gmail.com",
                        disabled: submitting,
                      }}
                    />
                  </li>
                )}
              </ul>
              <div className="ls-actions">
                <Button
                  basic
                  type="button"
                  disabled={submitting}
                  onClick={form.reset}
                >
                  cancel
                </Button>
                <Button
                  type="submit"
                  disabled={submitting}
                  loading={submitting}
                >
                  Apply
                </Button>
              </div>
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
