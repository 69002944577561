import styled from "styled-components";

export const FormPreviewWrapper = styled.div`
  display: flex;
  background: white;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 16px 32px;
  margin-left: 32px;
  gap: 16px;
  cursor: pointer;
  color: var(--gray-500);
  transition: 0.2s;

  ${({ isVisible }) =>
    !isVisible
      ? `
  background: var(--gray-100);
  outline: 1px dashed var(--gray-700);
  z-index: 1;
  `
      : ""}

  .ls-form-info {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .ls-form-actions {
    display: flex;
    gap: 8px;
  }

  .ls-icon-circle-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: linear-gradient(
      208deg,
      var(--primary) -23.71%,
      var(--primary-bright) 132.54%
    );
    box-shadow: 0px 4px 8px rgba(29, 86, 194, 0.25);
    position: relative;
    left: -60px;
    transition: 0.2s;

    h4 {
      font-size: 28px;
      color: var(--white);
    }
  }

  .ls-name {
    font-size: 20px;
    margin: 0 0 0 -32px;
    font-weight: 700;
    color: var(--primary-dark);
  }

  &:hover {
    box-shadow: 0px 4px 16px var(--gray-300);
  }

  &.animation-up {
    animation: goUp 0.6s;
    z-index: 2;
  }

  &.animation-down {
    animation: goDown 0.6s;
  }

  @keyframes goUp {
    from {
      transform: translateY(80px);
    }
    to {
      transform: translateY(0px);
    }
  }

  @keyframes goDown {
    from {
      transform: translateY(-80px);
    }
    to {
      transform: translateY(0px);
    }
  }
`;

export const FormsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px; 
    max-width: min(100%, 1200px);
    margin-bottom: 16px;
}
`;
