import styled from "styled-components";

export const Container = styled.div`
  width: min(1280px, 90vw);
  margin: 32px auto;

  > form {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: flex-start;
    gap: 64px;
    width: 100%;
    padding: 32px 32px 128px;
  }

  .lf-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ls-reply {
    display: flex;
    flex-direction: column;
    gap: 32px;
    list-style-type: none;
    padding-inline-start: 0;
    padding: 24px;

    > li {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > span {
        font-size: 1.2em;
        font-weight: 700;
      }

      > ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        list-style-type: none;
        padding-inline-start: 16px;

        > li {
          display: flex;
          flex-direction: column;
          gap: 4px;

          > span:first-child {
            font-size: 0.8em;
            font-weight: 700;
          }
        }
      }
    }
  }
`;
