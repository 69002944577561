import { Container } from "./styles";
import { Button, Grid } from "semantic-ui-react";
import { Outlet } from "components/Outlet";

export const Pricing = () => {
  return (
    <Outlet>
      <Container>
        <h1>Pricing</h1>
        <Grid columns={3} stackable>
          <Grid.Row>
            <Grid.Column>
              <h3>Light Plan</h3>
              <p>
                Start with our limited free plan and explore the basics of
                creating forms and landing pages with LightForm.
              </p>
              <h5>Features:</h5>
              <ul>
                <li>Basic form and landing page creation</li>
                <li>Access to the dashboard for viewing replies</li>
              </ul>
              <Button>Free</Button>
              <span>No card needed</span>
            </Grid.Column>
            <Grid.Column>
              <h3>Premium Plan</h3>
              <p>
                Unlock advanced features with our subscription plan for Premium
                users.
              </p>
              <h5>Features:</h5>
              <ul>
                <li>Advanced form and landing page creation</li>
                <li>Full customization options</li>
                <li>Access to premium templates</li>
                <li>Enhanced analytics and reporting</li>
                <li>Priority customer support</li>
              </ul>
              <Button>$14/month</Button>
              <span>or $144/year ($12/month)</span>
            </Grid.Column>
            <Grid.Column>
              <h3>Pro Plan</h3>
              <p>
                Unlock the full potential of LightForm with our Pro Plan. Ideal
                for businesses that require top-tier features and dedicated
                support to drive significant growth and engagement.
              </p>
              <h5>Features:</h5>
              <ul>
                <li>All Premium Plan features</li>
                <li>Unlimited form submissions</li>
                <li>Integration with third-party tools</li>
                <li>Advanced user management</li>
                <li>Dedicated account manager</li>
              </ul>
              <Button>$36/month</Button>
              <span>or $384/year ($32/month)</span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Outlet>
  );
};
