import { observer } from "mobx-react-lite";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as S from "./styles";
import { Pricing } from "./Pricing";

export const Settings = observer(() => {
  return (
    <S.Wrapper>
      <div className="main-section">
        <h3>Settings</h3>

        <Pricing />
      </div>
    </S.Wrapper>
  );
});
