import { Form as FormUI, Button, Card } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { useStore } from "structure";
import { Title } from "ui/common-styles";
import { multiValidators, required, min, max, equals } from "util/validators";
import { useState } from "react";
import { toast } from "react-toastify";

export const ResetPassword = () => {
  const { accountStore } = useStore();
  const history = useHistory();
  const [email, setEmail] = useState("");

  const handleRequestResetPassword = async (data) => {
    const res = await accountStore.requestResetPassword({
      email: data.email,
    });
    if (res.error) {
      return { code: res.error };
    } else {
      setEmail(data.email);
    }
  };

  const handleUpdatePassword = async (data) => {
    const res = await accountStore.resetPassword({
      email,
      password: data.password,
      code: data.code,
      password_confirmation: data.password_confirmation,
    });
    if (res.error) {
      return { code: res.error };
    } else {
      toast.success("Password seccessfully reset");
      history.push("/login");
    }
  };

  return (
    <>
      <Card centered>
        <Link to="/login"> back </Link>
        <Card.Header>
          <Title>Reset Password</Title>
        </Card.Header>
        {!email && (
          <Card.Content>
            <Form
              onSubmit={handleRequestResetPassword}
              render={({ handleSubmit, submitting }) => (
                <FormUI onSubmit={handleSubmit}>
                  <div>
                    <p>Enter the email connected to your account</p>
                  </div>
                  <FieldText
                    finalFormProps={{
                      name: "email",
                      validate: multiValidators([required, min(6), max(255)]),
                    }}
                    semanticProps={{
                      disabled: submitting,
                      type: "email",
                    }}
                    label="Email"
                  />

                  <Button
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                    fluid
                  >
                    Reset password
                  </Button>
                </FormUI>
              )}
            />
          </Card.Content>
        )}
        {!!email && (
          <Card.Content>
            <Form
              onSubmit={handleUpdatePassword}
              render={({ handleSubmit, submitting }) => (
                <FormUI onSubmit={handleSubmit}>
                  <div>
                    <p>
                      Check your email {email} for one-time code and choose a
                      new password.
                    </p>
                    <p>The code will be available for 10 min only</p>
                    <div>
                      <p>didn't get the code?</p>
                      <Button
                        type="button"
                        basic
                        disabled={submitting}
                        onClick={() => setEmail("")}
                      >
                        Change the email
                      </Button>
                      <Button
                        type="button"
                        basic
                        disabled={submitting}
                        onClick={() => handleRequestResetPassword({ email })}
                      >
                        Send code agaign
                      </Button>
                    </div>
                  </div>

                  <FieldText
                    finalFormProps={{
                      name: "code",
                      validate: multiValidators([required, min(6), max(6)]),
                    }}
                    semanticProps={{
                      disabled: submitting,
                    }}
                    label="Enter the code sent to your email"
                  />

                  <FieldText
                    finalFormProps={{
                      name: "password",
                      validate: multiValidators([required, min(8), max(255)]),
                    }}
                    semanticProps={{
                      disabled: submitting,
                      type: "password",
                    }}
                    label="Password"
                  />
                  <FieldText
                    finalFormProps={{
                      name: "password_confirmation",
                      validate: multiValidators([
                        required,
                        min(8),
                        max(255),
                        equals("password"),
                      ]),
                    }}
                    semanticProps={{
                      disabled: submitting,
                      type: "password",
                    }}
                    label="password again"
                  />

                  <Button
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                    fluid
                  >
                    Update password
                  </Button>
                </FormUI>
              )}
            />
          </Card.Content>
        )}
      </Card>
    </>
  );
};
