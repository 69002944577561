import { Container } from "./styles";
import { Block } from "./Block";
import { Form } from "react-final-form";
import { Button } from "semantic-ui-react";
import { publicEndpoints } from "structure/endpoints";
import { useParams } from "react-router-dom";
import { useState } from "react";

const PAGE_STATUSES = {
  FORM: 0,
  SUCCESS: 1,
  ERROR: 2,
};

export const Content = ({ data }) => {
  const { username, projectSlug } = useParams();
  const [pageStatus, setPageStatus] = useState(PAGE_STATUSES.FORM);
  const [replyContent, setReplyContent] = useState(null);
  const isLive = username && projectSlug;
  const submitForm = async (reply) => {
    console.log(reply);

    const formReply = createFormReply(data, reply);
    if (username && projectSlug) {
      await publicEndpoints
        .sendReply(username, projectSlug, formReply)
        .then((res) => {
          console.log(res);
          setPageStatus(PAGE_STATUSES.SUCCESS);
        })
        .catch((e) => {
          console.log(e);
          setPageStatus(PAGE_STATUSES.ERROR);
        });
    } else {
      setReplyContent(formReply);
      setPageStatus(PAGE_STATUSES.SUCCESS);
    }
  };
  const settings = data.settings;

  const scrollToFirstError = (errors) => {
    let formId = Object.keys(errors || {})[0];
    if (!formId) return;
    let fieldId = Object.keys(errors?.[formId] || {})[0];
    if (!fieldId) return;

    const errorElement = document.querySelector(
      `[name="${formId}.${fieldId}"]`
    );

    if (errorElement) {
      setTimeout(() => {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
  };

  return (
    <Container>
      {!!settings.header && (
        <header>
          <ContentPart id="header" fields={settings.header} />
        </header>
      )}
      {pageStatus === PAGE_STATUSES.ERROR && (
        <>
          {!!settings.error ? (
            <ContentPart id="error" fields={settings.error} />
          ) : (
            <h1>Error accured</h1>
          )}
        </>
      )}
      {pageStatus === PAGE_STATUSES.SUCCESS && (
        <>
          {!!settings.success ? (
            <ContentPart id="success" fields={settings.success} />
          ) : (
            <h1>Submit successfully</h1>
          )}
          {!isLive && (
            <ul className="ls-reply">
              {replyContent.map((form, idx) => (
                <li key={idx}>
                  <span>{form.name}</span>
                  <ul>
                    {form.fields.map((field, s_idx) => (
                      <li key={s_idx}>
                        <span>{field.field}</span>
                        <span>{field.value || "-"}</span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
      {pageStatus === PAGE_STATUSES.FORM && (
        <Form
          onSubmit={submitForm}
          render={({ handleSubmit, submitting, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                {data.forms.map((form) => {
                  return (
                    <div className="lf-form" key={form.id}>
                      <h1>{form.name}</h1>
                      <ContentPart id={form.id} fields={form.fields} />
                    </div>
                  );
                })}
                <div onClick={() => scrollToFirstError(errors)}>
                  <Button
                    type="submit"
                    loading={submitting}
                    disabled={submitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            );
          }}
        />
      )}

      <footer>
        {!!settings.footer && (
          <>
            <ContentPart id="footer" fields={settings.footer} />
            <hr />
          </>
        )}
        <p>
          Built with <a href="/">LightForm</a>
        </p>
        <ul>
          <li>
            <p>© 2024 LightForm </p>
          </li>
          <li>
            <a href="/">LightForm terms & conditions</a>
          </li>
          <li>
            <a href="/">LightForm pricavy policy</a>
          </li>
        </ul>
      </footer>
    </Container>
  );
};

const createFormReply = (data, reply) => {
  return data.forms.map((form) => {
    const formReply = reply[form.id];
    return {
      name: form.name,
      fields: form.fields
        .map((field, idx) => {
          const fieldData = Object.values(field)[0];
          if (!fieldData.field) return null;
          return {
            field: fieldData.field,
            value: formReply?.[`f-${idx}`] || "",
          };
        })
        .filter((item) => item),
    };
  });
};

const ContentPart = ({ id, fields }) => {
  return (
    <>
      {fields.map((field, idx) => {
        return (
          <div key={idx}>
            {Object.entries(field).map(([type, data]) => (
              <Block
                key={`${id}.f-${idx}`}
                type={type}
                data={data}
                blockId={`${id}.f-${idx}`}
              />
            ))}
          </div>
        );
      })}
    </>
  );
};
