import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .main-section {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    h3 {
      font-family: var(--title-font);
      color: var(--primary);
      font-weight: 700;
      font-size: 64px;
    }
  }
`;
