import { makeObservable, observable, computed, action } from "mobx";

export class View {
  sidebarContent = null;

  constructor(root) {
    this.rootStore = root;
    makeObservable(this, {
      sidebarContent: observable.ref,
      hasSidebarContent: computed,
      setSidebarContent: action,
    });
  }

  get hasSidebarContent() {
    return this.sidebarContent !== null;
  }

  setSidebarContent(content) {
    this.sidebarContent = content;
  }
}
