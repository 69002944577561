import { observer } from "mobx-react-lite";
import * as S from "./styles";

export const Statistics = observer(() => {
  return (
    <S.Wrapper>
      <div className="main-section">
        <h3>Statistics</h3>
        <p>*Comming soon</p>
      </div>
    </S.Wrapper>
  );
});
