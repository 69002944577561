import { Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { FieldTextArea } from "ui/FieldTextArea";

export const Webhook = observer(({ project, updateSettings }) => {
  const projectId = project.id;
  const projectSettings = project.settings;

  const onSubmit = async (data) => {
    await updateSettings(projectId, data);
  };

  return (
    <GeneralEdit>
      <h4>Webhook</h4>
      <p>Lorem ipsum</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ul>
                <li>
                  <FieldCheckbox
                    finalFormProps={{
                      name: "use_reply_webhook",
                      initialValue: projectSettings.use_reply_webhook,
                    }}
                    semanticProps={{
                      label: "Send webhook",
                      disabled: submitting,
                    }}
                    initialChecked={projectSettings.use_reply_webhook}
                  />
                </li>
                {values.use_reply_webhook && (
                  <>
                    <li>
                      <FieldText
                        label="Enter webhook URL"
                        finalFormProps={{
                          name: "reply_webhook",
                          initialValue: projectSettings.reply_webhook,
                        }}
                        semanticProps={{
                          placeholder: "example.com/example",
                          disabled: submitting,
                        }}
                      />
                    </li>
                    <li>
                      <FieldTextArea
                        label="Enter webhook headers (JSON format)"
                        finalFormProps={{
                          name: "reply_webhook_headers",
                          initialValue: projectSettings.reply_webhook_headers,
                        }}
                        semanticProps={{
                          placeholder: `{"Authorization": "Bearer token"}`,
                          disabled: submitting,
                        }}
                      />
                    </li>
                  </>
                )}
              </ul>
              <div className="ls-actions">
                <Button
                  basic
                  type="button"
                  disabled={submitting}
                  onClick={form.reset}
                >
                  cancel
                </Button>
                <Button
                  type="submit"
                  disabled={submitting}
                  loading={submitting}
                >
                  Apply
                </Button>
              </div>
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
