import { useHistory } from "react-router-dom";
import { ProjectPreviewWrapper } from "./styles";
import { formatDate } from "util/helpers";
import { Icon } from "assets/icons/Icon";

export const ProjectPreview = ({ data }) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`/dashboard/project/${data.id}`);
  };

  return (
    <ProjectPreviewWrapper onClick={onClick}>
      <div className="ls-icon-circle-primary">
        <Icon name="briefcase" />
      </div>
      <p className="ls-name">{data.name}</p>
      <p>
        <Icon name="time" /> {formatDate(data.updated_at, "datetime")}
      </p>
    </ProjectPreviewWrapper>
  );
};
