import { observer } from "mobx-react-lite";
import { Projects } from "../Projects";
import { Statistics } from "../Statistics";

export const Home = observer(() => {
  return (
    <>
      <Projects />
      <Statistics />
    </>
  );
});
