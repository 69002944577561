import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "structure";
import { Container } from "./styles";
import { Login } from "./Login";
import { Register } from "./Register";
import { EmailVerification } from "./EmailVerification";
import { ResetPassword } from "./ResetPassword";

export const Enter = ({ currentScreen }) => {
  const history = useHistory();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;
  useEffect(() => {
    if (currentAccount !== null) {
      history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      {currentScreen === "register" && <Register />}
      {currentScreen === "login" && <Login />}
      {currentScreen === "verify" && <EmailVerification />}
      {currentScreen === "reset" && <ResetPassword />}
    </Container>
  );
};
