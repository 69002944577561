import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import * as S from "./styles";
import { Player } from "ui/Player";
import { useState } from "react";

export const Video = ({ blockId, initialValues }) => {
  const [values, setValues] = useState({});
  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-12.field`}
        initialValue={initialValues.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder="field text"
          />
        )}
      </Field>
      <Field
        name={`${blockId}.tool-12.url`}
        initialValue={initialValues.url || ""}
      >
        {(props) => (
          <S.Field>
            <label>Url</label>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={(e, data) => {
                console.log(e.target.value);
                setValues({ ...values, url: e.target.value });
                props.input.onChange(e);
              }}
              className="ls-contentEditable ls-field"
              placeholder="field text"
            />
          </S.Field>
        )}
      </Field>
      <Player url={values.url} />
    </S.Block>
  );
};
