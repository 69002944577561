import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Icon, Checkbox, Button } from "semantic-ui-react";
import * as S from "./styles";
import { FieldArray } from "react-final-form-arrays";

export const RadioGroup = ({ blockId, initialValues }) => {
  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-4.field`}
        initialValue={initialValues.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder="field text"
          />
        )}
      </Field>
      <Field
        name={`${blockId}.tool-4.desc`}
        initialValue={initialValues.desc || ""}
      >
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="description (optional)"
            />
          </S.Field>
        )}
      </Field>
      <FieldArray
        name={`${blockId}.tool-4.options`}
        defaultValue={initialValues.options || []}
      >
        {({ fields }) => (
          <S.RadioOptions>
            <h4>Options</h4>
            <Button type="button" onClick={() => fields.push("")}>
              + Add option
            </Button>
            {fields.map((name, idx) => (
              <Field key={name} name={name}>
                {(props) => (
                  <S.Field className="ls-field">
                    <div className="radio-demo"></div>
                    <TextareaAutosize
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      className="ls-contentEditable"
                      placeholder={`option #${idx + 1}`}
                    />
                    <Button
                      className="remove-option"
                      type="button"
                      onClick={() => fields.remove(idx)}
                    >
                      <Icon name="delete" />
                    </Button>
                  </S.Field>
                )}
              </Field>
            ))}
          </S.RadioOptions>
        )}
      </FieldArray>

      <Field name={`${blockId}.tool-4.required`} value={initialValues.required}>
        {(props) => (
          <S.Field>
            <Checkbox
              name={props.input.name}
              checked={props.input.value || false}
              onChange={(e, data) => {
                props.input.onChange(data.checked);
              }}
              label="Required"
            />
          </S.Field>
        )}
      </Field>
    </S.Block>
  );
};
