import { Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { GeneralEdit } from "ui/common-styles";

import { BUILD_PARTS } from "util/consts";
import { toast } from "react-toastify";
import { useStore } from "structure";
import { Link } from "react-router-dom";

export const BuildPart = observer(({ partId, project }) => {
  const projectId = project.id;
  const { projectsStore } = useStore();
  const partSection = project.settings?.[BUILD_PARTS[partId].key];

  const initIsActive = partSection?.isActive ? true : false;
  const hasContent = partSection?.content?.length ? true : false;
  console.log(partSection?.content);
  const onSubmit = async (data) => {
    const update = {
      [BUILD_PARTS[partId].key]: {
        content: partSection?.content || [],
        isActive: data.isActive,
      },
    };
    const res = await projectsStore.saveBuildContent(project, update);
    if (res) {
      toast.success(`${BUILD_PARTS[partId].name} saved successfully`);
    }
  };

  return (
    <GeneralEdit>
      <h4>{BUILD_PARTS[partId].name}</h4>
      <p>{BUILD_PARTS[partId].desc}</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              {hasContent ? (
                <>
                  <FieldCheckbox
                    finalFormProps={{
                      name: "isActive",
                    }}
                    semanticProps={{
                      label: `Use ${BUILD_PARTS[partId].key}`,
                      disabled: submitting,
                    }}
                    initialChecked={initIsActive}
                  />
                  <Link
                    to={`/dashboard/project/${projectId}/part/${BUILD_PARTS[partId].key}`}
                  >
                    Edit your custom {BUILD_PARTS[partId].name}
                  </Link>
                  <div className="ls-actions">
                    <Button
                      basic
                      type="button"
                      disabled={submitting}
                      onClick={form.reset}
                    >
                      cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={submitting}
                      loading={submitting}
                    >
                      Apply
                    </Button>
                  </div>
                </>
              ) : (
                <Link
                  to={`/dashboard/project/${projectId}/part/${BUILD_PARTS[partId].key}`}
                >
                  Create custom {BUILD_PARTS[partId].name}
                </Link>
              )}
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
