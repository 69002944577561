import { useParams, useHistory, Link } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { observable } from "mobx";
import { useStore } from "structure";
import { toast } from "react-toastify";
import { AsText } from "ui/common-styles";
import { Button, Popup, Tab } from "semantic-ui-react";
import { Confirm } from "ui/Confirm";
import { useEffect, useState } from "react";
import { Loading } from "components/shared/Loading";
import { Icon } from "assets/icons/Icon";
import { DuplicateProjectModal } from "./DuplicateProjectModal";
import { NoData } from "components/shared/NoData";
import * as S from "./styles";
import { Overview } from "./Panes/Overview";
import { Design } from "./Panes/Design";
import { Build } from "./Panes/Build/Build";
import { Settings } from "./Panes/Settings";
import { Submissions } from "./Panes/Submissions";

export const Project = observer(() => {
  const history = useHistory();
  let { projectId } = useParams();
  const { projectsStore, accountStore } = useStore();
  const {
    currentAccount: { username },
  } = accountStore;
  const { currentTab } = projectsStore;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [isDraftConfirmOpen, setIsDraftConfirmOpen] = useState(false);
  const [isPublishConfirmOpen, setIsPublishConfirmOpen] = useState(false);
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFind, setIsNotFind] = useState(false);

  const handleTabChange = (e, { activeIndex }) =>
    projectsStore.setCurrentTab(activeIndex);

  const lcoalStore = useLocalObservable(
    () => ({
      order: project?.settings?.order || [],

      setOrder(order) {
        this.order = order;
      },

      replaceOrder(idx1, idx2) {
        const item1 = this.order?.[idx1];
        const item2 = this.order?.[idx2];
        let newOrder = [...this.order];
        newOrder[idx2] = item1;
        newOrder[idx1] = item2;
        this.setOrder(newOrder);
      },

      setVisibility(idx, isVisible) {
        let newOrder = [...this.order];
        newOrder[idx] = { form: this.order[idx].form, isVisible };
        this.setOrder(newOrder);
      },
    }),
    {
      order: observable.ref,
    }
  );

  useEffect(() => {
    projectsStore.getById(projectId).then((project) => {
      if (project) {
        setProject(project);
        lcoalStore.setOrder(project?.settings?.order || []);
        setIsLoading(false);
      } else {
        setIsNotFind(true);
      }
    });

    // eslint-disable-next-line
  }, [projectId, projectsStore.isLoading]);

  const handleDeleteProject = async () => {
    await projectsStore.delete(projectId);
    toast.success("Project deleted successfully");
    history.push(`/dashboard/projects`);
  };

  const handlePublicProject = async (isPublic) => {
    await projectsStore.update(projectId, { isPublic });
    toast.success(
      isPublic ? "Project is published!" : "Project is unpublished"
    );
  };

  const updateSettings = async (projectId, data) => {
    let projectSettingsData = {};
    let projectPublicSettingsData = {};
    PUBLIC_SETTING_PROPS.forEach((keyName) => {
      if (keyName in data) projectPublicSettingsData[keyName] = data[keyName];
    });
    PRIVATE_SETTING_PROPS.forEach((keyName) => {
      if (keyName in data) projectSettingsData[keyName] = data[keyName];
    });

    await projectsStore.saveProjectSettings(
      projectId,
      projectSettingsData,
      projectPublicSettingsData
    );
    setProject(projectsStore.findProjectById(projectId));
  };

  if (isNotFind) {
    <NoData
      title="Project not found"
      text="How you get here? brobably a broken link./nfeel free to contact us in case this issue repeat itself."
    />;
  }

  if (isLoading) {
    return <Loading />;
  }

  const projectURL = project ? `/lf/${username}/${project.slug}` : "/";

  const panes = [
    {
      menuItem: "Overview",
      render: () => (
        <Overview project={project} updateSettings={updateSettings} />
      ),
    },
    {
      menuItem: "Build",
      render: () => <Build lcoalStore={lcoalStore} project={project} />,
    },

    {
      menuItem: "Design",
      render: () => (
        <Design project={project} updateSettings={updateSettings} />
      ),
    },
    {
      menuItem: "Submissions",
      render: () => (
        <Submissions project={project} updateSettings={updateSettings} />
      ),
    },
    {
      menuItem: "Settings",
      render: () => (
        <Settings project={project} updateSettings={updateSettings} />
      ),
    },
  ];

  return (
    <S.Wrapper>
      <div className="ls-actions">
        <AsText as={Link} to={`/dashboard/projects`} color="black">
          <Icon name="back" />
          back to All projects
        </AsText>
        <ul>
          <li>
            <AsText as={Link} to={projectURL}>
              <Icon name="share" />
              Share
            </AsText>
          </li>

          <li>
            <AsText as={Link} to={`/dashboard/project/${projectId}/preview`}>
              <Icon name="visibility" />
              Preview
            </AsText>
          </li>

          <li>
            <AsText onClick={() => setOpenDuplicateModal(true)}>
              <Icon name="copy" />
              Duplicate
            </AsText>
          </li>

          <li>
            <AsText onClick={() => setIsDeleteModalOpen(true)} color="red">
              <Icon name="delete" />
              Delete
            </AsText>
          </li>
        </ul>
      </div>

      <div className="project-section">
        <ProjectName name={project?.name} />

        <div className="project-details">
          {project?.isPublic ? (
            <Popup
              trigger={
                <Button
                  color="green"
                  onClick={() => setIsDraftConfirmOpen(true)}
                >
                  Public
                </Button>
              }
              content="Click to unpublish"
              basic
            />
          ) : (
            <Popup
              trigger={
                <Button
                  color="grey"
                  onClick={() => setIsPublishConfirmOpen(true)}
                >
                  Draft
                </Button>
              }
              content="Click to publish"
              basic
            />
          )}
        </div>
      </div>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={currentTab}
        onTabChange={handleTabChange}
        style={{ marginTop: -40 }}
      />

      <Confirm
        content="Are you sure you want to pernently remove this project?"
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteProject}
        size="tiny"
      />

      <Confirm
        content="Are you sure you want to make the project public?"
        open={isPublishConfirmOpen}
        onCancel={() => setIsPublishConfirmOpen(false)}
        onConfirm={() => handlePublicProject(true)}
        size="tiny"
      />

      <Confirm
        content="Are you sure you want to unpublish this project and restore it to draft?"
        open={isDraftConfirmOpen}
        onCancel={() => setIsDraftConfirmOpen(false)}
        onConfirm={() => handlePublicProject(false)}
        size="tiny"
      />

      <DuplicateProjectModal
        open={openDuplicateModal}
        setOpen={setOpenDuplicateModal}
        projectId={projectId}
      />
    </S.Wrapper>
  );
});

const PUBLIC_SETTING_PROPS = [
  "steps",
  "frame",
  "nextBtn",
  "submitBtn",
  "template",
  "branding",
  "color",
];

const PRIVATE_SETTING_PROPS = [
  "desc",
  "use_reply_email",
  "use_reply_webhook",
  "reply_email",
  "reply_webhook",
  "reply_webhook_headers",
];

const ProjectName = ({ name }) => {
  return (
    <h3 key={name} className="project-name">
      {name || "Untitled project"}
    </h3>
  );
};
