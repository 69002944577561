import { Grid, Image } from "semantic-ui-react";

export const Testimonials = () => {
  return (
    <div className="ls-testamonials">
      <h2>Customer Reviews</h2>
      <Grid columns={2} divided stackable>
        <Grid.Row>
          <Grid.Column>
            <Image src="path_to_customer1_photo.jpg" avatar />
            <p>
              "LightForm has revolutionized how we collect customer feedback.
              It's user-friendly and incredibly powerful." – Jane D., Marketing
              Manager
            </p>
          </Grid.Column>
          <Grid.Column>
            <Image src="path_to_customer2_photo.jpg" avatar />
            <p>
              "Creating landing pages has never been easier. LightForm's
              customization options are fantastic." – John S., Small Business
              Owner
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
