import { observer } from "mobx-react-lite";
import { PanelTemplate, ActiveTag } from "../PanelTemplate";
import { UsingSteps } from "./content/UsingSteps";
import { ThemeColor } from "./content/ThemeColor";
import { Template } from "./content/Template";
import { FrameFields } from "./content/FrameFields";

export const Design = observer(({ project, updateSettings }) => {
  const projectPublicSettings = project.public_settings;
  const themeColor = projectPublicSettings.color || "#00ff44";

  const pages = [
    {
      name: "Template",
      value: `${projectPublicSettings.template || "default"}`,
      content: <Template project={project} updateSettings={updateSettings} />,
    },
    {
      name: "Theme color",
      value: (
        <div className="ls-tag" style={{ background: themeColor }}>
          <span style={{ filter: "invert() sepia()", fontWeight: 700 }}>
            {themeColor}
          </span>
        </div>
      ),
      content: <ThemeColor project={project} updateSettings={updateSettings} />,
    },
    {
      name: "Using Steps",
      value: <ActiveTag isActive={projectPublicSettings.steps} />,
      content: <UsingSteps project={project} updateSettings={updateSettings} />,
    },
    {
      name: "Frame fields",
      value: <ActiveTag isActive={projectPublicSettings.frame} />,
      content: (
        <FrameFields project={project} updateSettings={updateSettings} />
      ),
    },
  ];

  return <PanelTemplate pages={pages} />;
});
