import { observer } from "mobx-react-lite";
import { PanelTemplate } from "../PanelTemplate";
import { Slug } from "./content/Slug";
import { Info } from "./content/Info";
import { Description } from "./content/Description";
import { Name } from "./content/Name";
import { Publish } from "./content/Publish";

export const Overview = observer(({ project, updateSettings }) => {
  const projectSettings = project.settings;

  const pages = [
    {
      name: "Project name",
      value: project?.name || "Untitled",
      content: <Name project={project} />,
    },
    {
      name: "Descriptoin",
      value: projectSettings?.desc || "No description",
      content: (
        <Description project={project} updateSettings={updateSettings} />
      ),
    },
    {
      name: "Slug",
      value: project?.slug || "unknown",
      content: <Slug project={project} updateSettings={updateSettings} />,
    },
    {
      name: "Publish status",
      value: project?.isPublic ? (
        <span className="ls-tag ls-active">Public</span>
      ) : (
        <span className="ls-tag">Draft</span>
      ),
      content: <Publish project={project} />,
    },
    {
      name: "More Project Info",
      value: "",
      content: <Info project={project} />,
    },
  ];

  return <PanelTemplate pages={pages} />;
});
