import styled from "styled-components";

export const Container = styled.div`
  header,
  .ls-hero,
  .ls-features,
  .ls-testamonials,
  footer {
    width: 100%;
    padding: 32px 5vw;
  }

  header {
    display: flex;
    justify-content: space-between;

    nav {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 16px;

      .ls-login {
        color: var(--primary);
        font-weight: 600;
        font-size: 1.4rem;
      }
      .ls-signup {
        color: white;
        background: var(--primary);
        font-weight: 600;
        font-size: 1.4rem;
        padding: 8px 24px;
        border-radius: 40px;
      }
    }
  }

  .ls-hero {
    min-height: 80vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    > * {
      max-width: 600px;
    }
    h1 {
      font-size: 3rem;
    }
  }

  .ls-features {
    padding: 64px 5vw;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: var(--gray-300);

    h2 {
      font-size: 2rem;
      text-align: center;
    }
    .ui.grid > .row > .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        text-align: center;
      }
    }
  }

  .ls-testamonials {
    padding: 64px 5vw;
    display: flex;
    flex-direction: column;
    gap: 32px;

    h2 {
      font-size: 2rem;
      text-align: center;
    }
  }

  footer {
    min-height: 320px;
    padding: 64px 5vw;
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: var(--gray-200);
    justify-content: center;
    font-size: 1.4rem;
  }
`;
