import styled from "styled-components";

export const PaneWrapper = styled.div`
  background: rgba(255, 255, 255, 0.65);
  border-radius: 8px;
  backdrop-filter: blur(8px);
  box-shadow: rgba(25, 26, 33, 0.05) 0px 4px 16px;
  height: calc(100vh - 348px);
  margin-top: 24px;
  animation: fadeIn 0.4s;
  display: flex;

  .ls-option-list {
    min-width: 320px;
    width: 40%;
    border-right: 1px solid var(--gray-500);
    > li {
      &.ls-selected {
        > button,
        button:hover {
          background: var(--secondary);
        }
      }
      > button {
        display: flex;
        gap: 16px;
        margin: 4px 12px;
        padding: 12px 24px;
        border-radius: 8px;
        width: calc(100% - 24px);
        text-align: left;
        min-height: 48px;

        > span:first-child {
          width: 160px;
        }

        > span:last-child,
        > div:last-child {
          min-width: 160px;
          max-width: calc(100% - 176px);
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;

          > .ls-tag {
            display: inline-block;
            padding: 4px 8px;
            border-radius: 4px;
            background: var(--gray-500);

            &.ls-active {
              background: var(--primary);
              color: white;
            }
          }
        }

        :hover {
          background: var(--gray-300);
          color: black;
        }
      }
    }
  }

  .ls-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 75%;
    min-width: 400px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
    max-height: calc(100% - 16px);
    overflow-y: auto;
    margin: 8px 8px 8px 0;

    > * {
      animation: fadeIn 0.4s;
    }
  }

  h3 {
    font-family: var(--title-font);
    color: var(--primary);
    font-weight: 700;
    font-size: 48px;
  }

  .ls-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;

    > li {
      > span:first-child {
        display: inline-block;
        min-width: 140px;
        font-weight: 500;
      }
    }

    li.ls-one-line {
      margin-bottom: 16px;
    }
  }

  .ls-header {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .ls-actions {
    padding-top: 16px;
    //border-top: 1px solid var(--gray-500);
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 16px;
  }

  .ui.input.ls-color-picker > input {
    padding: 0px;
    max-width: 60px;
    border: none;
  }
`;
