import { observer } from "mobx-react-lite";
import {
  SidebarPushable,
  SidebarPusher,
  Sidebar as SidebarSemantic,
} from "semantic-ui-react";
import { useStore } from "structure";

export const Sidebar = observer(({ children }) => {
  const { viewStore } = useStore();

  return (
    <SidebarPushable>
      <SidebarSemantic
        animation="overlay"
        icon="labeled"
        direction="right"
        onHide={() => viewStore.setSidebarContent(null)}
        visible={viewStore.hasSidebarContent}
        width="wide"
      >
        {viewStore.sidebarContent}
      </SidebarSemantic>

      <SidebarPusher dimmed={viewStore.hasSidebarContent}>
        {children}
      </SidebarPusher>
    </SidebarPushable>
  );
});
