import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .main-section {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    h3 {
      font-family: var(--title-font);
      color: var(--primary);
      font-weight: 700;
      font-size: 64px;
    }
  }
`;

export const PricingWrapper = styled.div`
  display: flex;
  align-items: center;
  color: rgb(51, 51, 51);
  flex-direction: column;

  .ls-plans {
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 24px;
    > li {
      background: #fff;
      border: 1px solid #ddd;
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }

  h3 {
    margin: 0 0 10px 0;
    font-size: 1.5em !important;
    color: #007bff;
  }
  h5 {
    margin: 10px 0 5px 0;
    font-size: 1.2em;
    color: #0056b3;
  }
  p {
    margin: 0 0 15px 0;
    font-size: 1.1em;
  }
  ul ul {
    list-style: none;
  }

  span {
    display: block;
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
  }
`;
