import { makeObservable, observable, action, computed } from "mobx";
import { auth } from "../endpoints";

export class Account {
  currentAccount = null;
  isLoading = false;
  loginDetails = null;

  constructor() {
    if (localStorage.getItem("account") && localStorage.getItem("token")) {
      this.currentAccount = JSON.parse(localStorage.getItem("account"));
      console.log(this.currentAccount);
    }
    makeObservable(this, {
      currentAccount: observable,
      isLoading: observable,
      loginDetails: observable.ref,
      isAdmin: computed,
      login: action,
      register: action,
      registerWithGoogleData: action,
      logout: action,
    });
  }

  get isAdmin() {
    return this.currentAccount?.id === 1;
  }

  get isAuthanticated() {
    return this.currentAccount !== null;
  }

  async login({ email, password, remember = true }) {
    this.isLoading = true;
    try {
      const res = await auth.login({
        email,
        password,
      });

      if (res.user) {
        this.loginDetails = null;
        this.currentAccount = res.user;
        localStorage.setItem("token", res.token);
        if (remember) localStorage.setItem("account", JSON.stringify(res.user));
        return { status: "success" };
      }
      this.loginDetails = { email, password };
      return res;
    } catch (e) {
      return { error: e };
    } finally {
      this.isLoading = false;
    }
  }

  async loginGoogle(token) {
    this.isLoading = true;
    const res = await auth.loginGoogle({
      token,
    });
    if (res.user) {
      this.currentAccount = res.user;
      localStorage.setItem("token", res.token);
    }
    this.isLoading = false;
    return res;
  }

  async register({ name, username, email, password, password_confirmation }) {
    this.isLoading = true;
    const res = await auth.register({
      name,
      username,
      email,
      password,
      password_confirmation,
    });
    if (res.status === "require_email_verification") {
      this.loginDetails = { email, password };
      this.isLoading = false;
      return true;
    }

    this.isLoading = false;
    return res;
  }
  async requestVerifyEmail(email) {
    this.isLoading = true;
    const res = await auth.requestEmailVerification({
      email,
    });
    this.isLoading = false;
    return res;
  }

  async verifyEmail({ code, email }) {
    this.isLoading = true;
    const res = await auth.registerVerification({
      email,
      code,
    });
    if (res.status === "success" && this.loginDetails) {
      if (await this.login(this.loginDetails)) {
        return "enter";
      }
    }
    this.isLoading = false;
    return res;
  }

  async requestResetPassword({ email }) {
    this.isLoading = true;
    const res = await auth.requestResetPassword({
      email,
    });
    this.isLoading = false;
    return res;
  }

  async resetPassword(data) {
    this.isLoading = true;
    const res = await auth.resetPassword(data);
    this.isLoading = false;
    return res;
  }

  async registerWithGoogleData(data) {
    this.isLoading = true;
    const res = await auth.registerGoogle(data);
    if (res.user) {
      this.currentAccount = res.user;
      localStorage.setItem("token", res.token);
      this.isLoading = false;
      return true;
    }
    this.isLoading = false;
    return false;
  }

  async logout() {
    this.isLoading = true;
    await auth.logout();
    localStorage.removeItem("account");
    localStorage.removeItem("token");
    this.currentAccount = null;
    this.isLoading = false;
    return true;
  }
}
