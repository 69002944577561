import { ProjectPreviewWrapper } from "./styles";
import { Icon } from "assets/icons/Icon";
import { useState } from "react";
import { CreateProjectModal } from "components/shared/CreateProjectModal";

export const NewProject = () => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  return (
    <>
      <ProjectPreviewWrapper onClick={onClick}>
        <div className="ls-icon-circle-primary">
          <Icon name="plus" />
        </div>
        <p className="ls-name">Create new project</p>
      </ProjectPreviewWrapper>

      <CreateProjectModal open={open} setOpen={setOpen} />
    </>
  );
};
