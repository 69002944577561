import { Button, Form as SemanticForm, Modal } from "semantic-ui-react";
import { FieldText } from "ui/FieldText";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { useStore } from "structure";

export const CreateProjectModal = observer(({ open, setOpen }) => {
  const { projectsStore } = useStore();
  const onSubmit = (data) => {
    projectsStore.create(data.name, data.public);
    setOpen(false);
  };
  return (
    <>
      <Modal
        size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>Create New Project</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
              return (
                <SemanticForm onSubmit={handleSubmit}>
                  <ul>
                    <li>
                      <FieldText
                        label="Project name"
                        finalFormProps={{
                          name: "name",
                        }}
                        semanticProps={{
                          placeholder: "Project name",
                        }}
                      />
                    </li>
                  </ul>
                  <Modal.Actions>
                    <Button
                      type="button"
                      basic
                      content="CANCEL"
                      onClick={() => setOpen(false)}
                    />
                    <Button type="submit" primary content="CREATE" />
                  </Modal.Actions>
                </SemanticForm>
              );
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
});
