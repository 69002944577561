import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  background: rgb(255 199 113);
  background: linear-gradient(
    17deg,
    rgb(255 199 113) 0%,
    rgb(255 187 225) 100%
  );

  .card {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .ui.form {
    margin-top: 24px;
    .ls-submit-error {
      color: var(--toastify-color-error);
      text-align: center;
      background: #ffe9e7;
      margin: 12px 0;
    }
    > .ui.button {
      margin-top: 24px;
    }
  }
`;

export const GoogleAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-300);

  > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .ls-account-content {
    font-size: 1.17em;
  }
`;
