import { Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { useStore } from "structure";
import { max, min, multiValidators, required } from "util/validators";

export const Slug = observer(({ project }) => {
  const { projectsStore } = useStore();
  const projectId = project.id;

  const onSubmit = async (data) => {
    const success = await projectsStore.update(projectId, { slug: data.slug });
    if (!success)
      return {
        slug: "This slug is already exist",
      };
  };

  return (
    <GeneralEdit>
      <h4>Slug</h4>
      <p>Lorem ipsum</p>

      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          form,
          hasSubmitErrors,
          submitErrors,
        }) => {
          console.log({ submitErrors });
          return (
            <form onSubmit={handleSubmit}>
              <FieldText
                label="Type slug"
                finalFormProps={{
                  name: "slug",
                  initialValue: project.slug,
                  validate: multiValidators([required, min(3), max(255)]),
                }}
                semanticProps={{
                  disabled: submitting,
                }}
              />
              <div className="ls-actions">
                <Button
                  basic
                  type="button"
                  disabled={submitting}
                  onClick={form.reset}
                >
                  cancel
                </Button>
                <Button
                  type="submit"
                  disabled={submitting}
                  loading={submitting}
                >
                  Apply
                </Button>
              </div>
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
