import { Button } from "semantic-ui-react";
import { Wrapper } from "./styles";
import illustration from "assets/illustrations/No data-pana.png";
import { useHistory } from "react-router-dom";

export const NoData = ({ title, text }) => {
  const history = useHistory();
  return (
    <Wrapper>
      <img src={illustration} alt={title || "no data"} />
      <div>
        {title && <h2>{title}</h2>}
        {text && <p>{text}</p>}
        <Button primary onClick={() => history.push(-1)}>
          Go back
        </Button>
      </div>
    </Wrapper>
  );
};
