import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { ProjectPreview } from "./ProjectPreview";
import * as S from "./styles";
import { NewProject } from "./NewProject";
import { useEffect, useState } from "react";
import { Loading } from "components/shared/Loading";

export const Projects = observer(() => {
  const { projectsStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    projectsStore.fetchProjects().then(() => setIsLoading(false));
    return () => setIsLoading(true);
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div style={{ height: 400 }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Wrapper>
      <div className="main-section">
        <h3>Projects</h3>
        <S.ProjectsWrapper>
          {projectsStore.projects.map((project) => (
            <ProjectPreview key={project.id} data={project} />
          ))}
          <NewProject />
        </S.ProjectsWrapper>
      </div>
    </S.Wrapper>
  );
});
