import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { Container } from "./styles";
import { useStore } from "structure";
import { useState } from "react";
import { Button } from "semantic-ui-react";
import { FieldText } from "ui/FieldText";
import { Form } from "react-final-form";
import { required } from "util/validators";

const PLAN_DETAILS = {
  premium: {
    name: "Premium plan",
    m_price: 14,
    y_price: 12,
    m_id: "P-2XN16748SF880143DM2SWHSA",
    y_id: "P-7NC12994TU026215TMYPNC6Q",
  },
  pro: {
    name: "Pro plan",
    m_price: 36,
    y_price: 32,
    m_id: "P-1E239733HE683381TMYPNDHY",
    y_id: "P-7SU22758AC817583DMYPNDTQ",
  },
};

export const Payment = () => {
  const history = useHistory();
  const { plan } = useParams();
  const { accountStore, paymentStore } = useStore();
  const { currentAccount } = accountStore;
  const [selectedPlan, setSelectedPlan] = useState("month");
  const planDetails = PLAN_DETAILS[plan];

  const onSubmit = async (data) => {
    const planId = planDetails[selectedPlan === "month" ? "m_id" : "y_id"];
    const res = await paymentStore.subscribe({
      planId,
      ...data,
    });
    if (res?.approval_url) {
      window.location.href = res?.approval_url;
    }
  };

  if (!planDetails) {
    <Redirect to={"/"} />;
  }

  return (
    <Container>
      <Button basic onClick={() => history.goBack()}>
        Back
      </Button>
      <div className="ls-header">
        <h1>Complete your order</h1>
        <p>Selected plan: {planDetails.name}</p>
      </div>

      <div className="ls-account">
        <h3>Your account</h3>
        {currentAccount ? (
          <div>
            <p>{currentAccount.email}</p>
            <span>
              If you want to use different account log out and sign in again
              with the right account.
            </span>
          </div>
        ) : (
          <div>
            <p>First, log into your account here: </p>
            <Link to="/login" />
            <p>or if you don't have one already, create one here: </p>
            <Link to="/register" />
          </div>
        )}
      </div>
      <div className="ls-cycle">
        <h3>Recuureing cycle</h3>
        <div className="ls-options">
          <Button
            className={selectedPlan === "month" ? "ls-selected" : ""}
            onClick={() => setSelectedPlan("month")}
          >
            <span>1 month</span>
            <span className="ls-price">{planDetails.m_price}$</span>
            <span>/month</span>
          </Button>
          <Button
            className={selectedPlan === "year" ? "ls-selected" : ""}
            onClick={() => setSelectedPlan("year")}
          >
            <span>12 month</span>
            <span className="ls-price">{planDetails.y_price}$</span>
            <span>/month</span>
            <span className="ls-save">
              save {(planDetails.m_price - planDetails.y_price) * 12}$
            </span>
          </Button>
        </div>
      </div>
      <div className="ls-payment">
        <h3>Payment</h3>
        <p>
          total:{" "}
          {selectedPlan === "month"
            ? planDetails.m_price
            : planDetails.y_price * 12}
          $
        </p>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FieldText
                  label="First name"
                  finalFormProps={{
                    name: "firstName",
                    validate: required,
                    initialValue: currentAccount.firstName || "",
                  }}
                  semanticProps={{
                    disabled: submitting,
                  }}
                />
                <FieldText
                  label="Last name"
                  finalFormProps={{
                    name: "lastName",
                    validate: required,
                    initialValue: currentAccount.lastName || "",
                  }}
                  semanticProps={{
                    disabled: submitting,
                  }}
                />
                <FieldText
                  label="Email"
                  finalFormProps={{
                    name: "email",
                    validate: required,
                    initialValue: currentAccount.email,
                  }}
                  semanticProps={{
                    type: "email",
                    disabled: submitting,
                  }}
                />
                <div className="ls-actions">
                  <Button
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                  >
                    Subscribe with PayPal
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
      <div className="ls-footer">
        <Link to="/">Terms of service</Link>
        <Link to="/">Privacy policy</Link>
      </div>
    </Container>
  );
};
