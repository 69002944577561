import { observer } from "mobx-react-lite";
import { PanelTemplate } from "../PanelTemplate";
import { Email } from "./content/Email";
import { Webhook } from "./content/Webhook";

export const Submissions = observer(({ project, updateSettings }) => {
  const projectSettings = project.settings;

  const pages = [
    {
      name: "Email notification",
      value: projectSettings?.use_reply_email ? (
        <span className="ls-tag ls-active">Active</span>
      ) : (
        <span className="ls-tag">Disabled</span>
      ),
      content: <Email project={project} updateSettings={updateSettings} />,
    },
    {
      name: "Webhook",
      value: projectSettings?.use_reply_webhook ? (
        <span className="ls-tag ls-active">Active</span>
      ) : (
        <span className="ls-tag">Disabled</span>
      ),
      content: <Webhook project={project} updateSettings={updateSettings} />,
    },
  ];

  return <PanelTemplate pages={pages} />;
});
