import { useEffect } from "react";
import { useHistory, Route, Switch, HashRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { Header } from "./Header";
import { Navigation } from "./Navigation";
import { Home } from "./Home";
import { Project } from "./Project";
import { CreateForm } from "./CreateForm";

import { Container } from "./styles";
import { Statistics } from "./Statistics";
import { Media } from "./Media";
import { Settings } from "./Settings";
import { Projects } from "./Projects";
import { Submissions } from "./Submissions/Submissions";
import { Loading } from "components/shared/Loading";
import { Sidebar } from "components/shared/Sidebar";
import { CreatePart } from "./CreatePart";

export const Dashboard = observer(() => {
  const history = useHistory();
  const { accountStore, isFirstLoading } = useStore();
  const { currentAccount } = accountStore;

  useEffect(() => {
    if (currentAccount === null) {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Sidebar>
      <Container>
        <Navigation />
        <section>
          <Header />
          <div className="ls-main-content">
            {isFirstLoading ? (
              <Loading />
            ) : (
              <Switch>
                <Route exact path="/dashboard/projects">
                  <Projects />
                </Route>
                <Route exact path="/dashboard/project/:projectId">
                  <Project />
                </Route>
                <Route exact path="/dashboard/project/:projectId/part/:partId">
                  <CreatePart />
                </Route>
                <Route exact path="/dashboard/project/:projectId/form/:formId">
                  <CreateForm />
                </Route>
                <Route exact path="/dashboard/submissions">
                  <Submissions />
                </Route>
                <Route exact path="/dashboard/statistics">
                  <Statistics />
                </Route>
                <Route exact path="/dashboard/media">
                  <Media />
                </Route>
                <Route exact path="/dashboard/settings">
                  <Settings />
                </Route>
                <Route path="/dashboard/">
                  <Home />
                </Route>
              </Switch>
            )}
          </div>
        </section>
      </Container>
    </Sidebar>
  );
});
