import { observer } from "mobx-react-lite";
import { GeneralEdit } from "ui/common-styles";
import { formatDate } from "util/helpers";

export const Info = observer(({ project }) => {
  const created = formatDate(project.created_at, "datetime");
  const updated = formatDate(project.updated_at, "datetime");

  return (
    <GeneralEdit>
      <h4>Project Info</h4>
      <p>Lorem ipsum</p>

      <div className="ls-main-block">
        <ul>
          <li className="ls-one-line">
            <span>created at</span>
            <span>{created}</span>
          </li>
          <li className="ls-one-line">
            <span>last update</span>
            <span>{updated}</span>
          </li>
        </ul>
      </div>
    </GeneralEdit>
  );
});
