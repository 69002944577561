import { Field } from "react-final-form";
import * as S from "./styles";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Dropdown } from "semantic-ui-react";

const splitOptions = [
  {
    key: "30",
    text: "30 - 70",
    value: "30",
  },
  {
    key: "50",
    text: "50 - 50",
    value: "50",
  },
  {
    key: "70",
    text: "70 - 30",
    value: "70",
  },
  {
    key: "100",
    text: "Full width",
    value: "100",
  },
];

export const Laytout = observer(({ blockId, localStore, initialValues }) => {
  console.log(initialValues);
  const [split, setSplit] = useState(initialValues.split || "50");
  const blocks =
    localStore?.blocks.find((elem) => elem.id === blockId)?.items || [];

  return (
    <div>
      <S.SplitWrapper className={`split-template-${split}`}>
        <Field
          name={`${blockId}.tool-13.items`}
          initialValues={initialValues || {}}
        >
          {(props) => (
            <>
              {blocks.map((block) => (
                <div key={`block-${block.id}`} className="border-block">
                  {block.element}
                </div>
              ))}
            </>
          )}
        </Field>
      </S.SplitWrapper>
      <br />
      <hr />
      <Field
        name={`${blockId}.tool-13.split`}
        initialValue={split}
        className="selectField"
      >
        {(props) => (
          <div>
            <label>Split row on:</label>
            <Dropdown
              name={props.input.name}
              value={props.input.value}
              onChange={(e, data) => {
                setSplit(data.value);
                props.input.onChange(data.value);
              }}
              fluid
              selection
              options={splitOptions}
            />
          </div>
        )}
      </Field>
    </div>
  );
});
