import { observer } from "mobx-react-lite";
import * as S from "./styles";
import { useEffect, useRef, useState } from "react"; // Import useState
import { useStore } from "structure";
import { Form } from "react-final-form";
import { Button, Modal } from "semantic-ui-react";
import { MediaItem } from "./MediaItem";
import { toast } from "react-toastify";

const BASE_URL = "https://sparks-creative-api.brightsite.co.il/";

export const MediaGalleryModal = observer(
  ({ onSelect, initialValue, trigger = null, multiple }) => {
    const { mediaStore } = useStore();
    let inputRef = useRef(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImages, setSelectedImages] = useState(initialValue || []);

    const handleImageClick = (item) => {
      if (multiple) {
        if (selectedImages.includes(item)) {
          setSelectedImages(selectedImages.filter((x) => x !== item));
        } else {
          setSelectedImages([...selectedImages, item]);
        }
      } else {
        setSelectedImages([item]);
      }
    };

    useEffect(() => {
      if (!(mediaStore.media?.images?.length > 0)) mediaStore.fetchMedia();
      // eslint-disable-next-line
    }, []);

    const onSubmit = () => {
      if (selectedFile) {
        mediaStore
          .addImage(selectedFile)
          .then(() => {
            toast.success("Image uploaded successfully");
            inputRef.current.value = null;
            setSelectedFile(null);
          })
          .catch((error) => {
            toast.success("Error accured while trying to upload the image");
            console.error("Error uploading file:", error);
          });
      }
    };

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]); // Set the selected file in state
    };

    const gallery = mediaStore?.media?.images || [];

    return (
      <Modal
        open={isOpenModal}
        onOpen={() => setIsOpenModal(true)}
        onClose={() => setIsOpenModal(false)}
        closeOnDimmerClick
        size="large"
        trigger={trigger}
      >
        <S.GalleryMediaWrapper>
          <p>Select an image</p>
          <S.GalleryWrapper>
            <div className="gallery-grid">
              {gallery.map((item) => (
                <MediaItem
                  key={item}
                  item={item}
                  handleImageClick={onSelect ? handleImageClick : null}
                  selectedImages={selectedImages}
                />
              ))}
            </div>

            <div className="gallery-upload">
              {!!onSelect ? (
                <div style={{ float: "right", paddingBottom: 16 }}>
                  <Button
                    basic
                    content="Cancel"
                    onClick={() => {
                      setIsOpenModal(false);
                    }}
                    style={{ marginRight: 16 }}
                  />
                  <Button
                    onClick={() => {
                      onSelect(
                        selectedImages.map(
                          (selectedImage) => `${BASE_URL}${selectedImage}`
                        )
                      );
                      setIsOpenModal(false);
                    }}
                    primary
                    content="Select"
                    disabled={selectedImages.length === 0}
                  />
                </div>
              ) : (
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, form }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <input
                          ref={inputRef}
                          type="file"
                          name="image"
                          onChange={handleFileChange}
                        />
                        <Button
                          type="submit"
                          primary
                          content="Upload"
                          disabled={!selectedFile}
                        />
                      </form>
                    );
                  }}
                />
              )}
            </div>
          </S.GalleryWrapper>
        </S.GalleryMediaWrapper>
      </Modal>
    );
  }
);
