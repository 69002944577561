import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Input, Checkbox } from "semantic-ui-react";
import * as S from "./styles";

export const TextArea = ({ blockId, initialValues }) => {
  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-3.field`}
        initialValue={initialValues.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder="field text"
          />
        )}
      </Field>
      <Field
        name={`${blockId}.tool-3.desc`}
        initialValue={initialValues.desc || ""}
      >
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="description (optional)"
            />
          </S.Field>
        )}
      </Field>
      <Field
        name={`${blockId}.tool-3.placeholder`}
        initialValue={initialValues.placeholder || ""}
      >
        {(props) => (
          <S.Field className="ls-field">
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="placeholder (optional)"
            />
          </S.Field>
        )}
      </Field>
      <Field name={`${blockId}.tool-3.required`} value={initialValues.required}>
        {(props) => (
          <S.Field>
            <Checkbox
              name={props.input.name}
              checked={props.input.value || false}
              onChange={(e, data) => {
                props.input.onChange(data.checked);
              }}
              label="Required"
            />
          </S.Field>
        )}
      </Field>
      <Field
        name={`${blockId}.tool-3.rows`}
        initialValue={initialValues.placeholder || ""}
      >
        {(props) => (
          <S.Field>
            <label>Rows</label>
            <Input
              name={props.input.name}
              value={props.input.value || 4}
              onChange={props.input.onChange}
              type="number"
              min={1}
              max={30}
              step={1}
            />
          </S.Field>
        )}
      </Field>
    </S.Block>
  );
};
