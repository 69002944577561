import { useState } from "react";
import { Button, Modal } from "semantic-ui-react";

export const Confirm = (props) => {
  const {
    header,
    content,
    open,
    onCancel,
    onConfirm,
    onClose = null,
    size,
    cancelButton,
    confirmButton,
    color,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    return onClose ? onClose() : onCancel();
  };
  return (
    <Modal size={size} onClose={onClose ? onClose : onCancel} open={open}>
      {!!header && <Modal.Header>{header}</Modal.Header>}
      {!!content && <Modal.Content>{content}</Modal.Content>}
      <Modal.Actions>
        <Button
          type="button"
          basic
          content={cancelButton || "Cancel"}
          onClick={onCancel}
          disabled={isLoading}
        />
        <Button
          type="button"
          onClick={handleConfirm}
          color={color || "blue"}
          content={confirmButton || "Cofrirm"}
          disabled={isLoading}
          loading={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};
