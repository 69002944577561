import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicEndpoints } from "structure/endpoints";
import { Content } from "./Content";

export const Website = () => {
  const { username, projectSlug } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [isNotFound, setIsNotFound] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    setIsLoading(true);
    publicEndpoints
      .getPage(username, projectSlug)
      .then((res) => setData(res))
      .catch(() => setIsNotFound(true))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, []);

  if (isLoading) return <p>LOADING ...</p>;

  if (isNotFound) return <p>404</p>;

  if (!data) return <p>Error</p>;

  return <Content data={data} />;
};
