import { Field } from "react-final-form";
import * as S from "./styles";
import TextareaAutosize from "react-textarea-autosize";
import { multiValidators, required } from "util/validators";
import { TextAreaWrapper } from "ui/common-styles";

export const TextArea = ({ blockId, data }) => {
  const validatorsArr = data.required ? [required] : [];
  return (
    <S.Block>
      {data.field && <p className="lf-field">{data.field}</p>}
      {data.desc && <p>{data.desc}</p>}
      <Field name={blockId} validate={multiValidators(validatorsArr)}>
        {(props) => (
          <TextAreaWrapper>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              placeholder={data.placeholder}
              minRows={data.rows}
            />
          </TextAreaWrapper>
        )}
      </Field>
    </S.Block>
  );
};
