import { Link, useParams } from "react-router-dom";
import { Icon } from "assets/icons/Icon";
import { AsText } from "ui/common-styles";
import { useEffect, useRef } from "react";
import { FormPreviewWrapper } from "./styles";

export const FormPreview = ({ data, idx, isVisible, lcoalStore }) => {
  const { projectId } = useParams();
  const indexRef = useRef(idx);
  const order = lcoalStore.order;

  const toggleVisible = async () => {
    lcoalStore.setVisibility(idx, !isVisible);
  };

  const up = async () => {
    lcoalStore.replaceOrder(idx, idx - 1);
  };

  const down = async () => {
    lcoalStore.replaceOrder(idx, idx + 1);
  };

  useEffect(() => {
    indexRef.current = idx;
  }, [idx]);

  const animationClass =
    indexRef.current > idx
      ? "animation-up"
      : indexRef.current < idx
      ? "animation-down"
      : "";

  return (
    <FormPreviewWrapper
      isVisible={isVisible}
      key={idx}
      className={animationClass}
    >
      <Link
        className="ls-form-info"
        to={`/dashboard/project/${projectId}/form/${data.id}`}
      >
        <div className="ls-icon-circle-primary">
          <h4>{idx + 1}</h4>
        </div>
        <p className="ls-name">{data.name}</p>
        {/* <p>
          <Icon name="time" /> {formatDate(data.updated_at, "datetime")}
        </p> */}
      </Link>

      <div className="ls-form-actions">
        <AsText onClick={up} disabled={idx === 0}>
          <Icon name="circleUp" />
        </AsText>
        <AsText onClick={down} disabled={idx === order.length - 1}>
          <Icon name="circleDown" />
        </AsText>

        <span></span>
        <AsText onClick={toggleVisible}>
          <Icon name={isVisible ? "visibility" : "visibilityOff"} />
        </AsText>
      </div>
    </FormPreviewWrapper>
  );
};
