import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .main-section {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    h3 {
      font-family: var(--title-font);
      color: var(--primary);
      font-weight: 700;
      font-size: 64px;
    }
  }
`;

export const ProjectsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px; 
    max-width: min(calc(100% - 96px), 1200px);
}
`;

export const ProjectPreviewWrapper = styled.div`
  display: flex;
  background: white;
  width: 200px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 16px 32px;
  margin-top: 32px;
  gap: 8px;
  cursor: pointer;
  color: var(--gray-500);
  transition: 0.2s;

  .ls-icon-circle-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    border-radius: 100%;
    background: linear-gradient(
      208deg,
      var(--primary) -23.71%,
      var(--primary-bright) 132.54%
    );
    box-shadow: 0px 4px 8px rgba(29, 86, 194, 0.25);
    position: relative;
    top: -48px;
    transition: 0.2s;

    svg {
      height: 48px;
      width: 48px;
      margin-bottom: 4px;
      fill: var(--white);
    }
  }

  .ls-name {
    font-size: 20px;
    margin: -32px 0 8px;
    font-weight: 700;
    color: var(--primary-dark);
  }

  &:hover {
    box-shadow: 0px 4px 16px var(--gray-500);
    background-color: rgba(255, 255, 255, 0.85);
    .ls-icon-circle-primary {
      top: -56px;
    }
  }
`;
