import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 1px);
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: var(--gray-100);
  background-image: linear-gradient(
    160deg,
    var(--gray-300) 0%,
    var(--gray-100) 100%
  );
  & > section {
    width: calc(100vw - 256px);
    height: 100vh;
    overflow: auto;
    padding-top: 96px;

    .ls-main-content {
      height: calc(100% - 14px);
      padding: 8px;
      overflow: auto;
    }
  }

  .ls-dashboard-panel {
    display: flex;
    flex: 1;
    width: 100%;
    & > div {
      padding: 32px 48px;
      overflow: auto;
    }
    & > div:first-child {
      width: 250px;
      background: #6e917d;
    }
    & > div:last-child {
      flex: 1;
    }
  }
`;
