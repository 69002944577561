import { observer } from "mobx-react-lite";
import { PanelTemplate } from "../PanelTemplate";
import { NextBtn } from "./content/NextBtn";
import { SubmitBtn } from "./content/SubmitBtn";

export const Settings = observer(({ project, updateSettings }) => {
  const projectPublicSettings = project.public_settings;

  const pages = [
    {
      name: "Next Button",
      value: projectPublicSettings?.nextBtn || "Next",
      content: <NextBtn project={project} updateSettings={updateSettings} />,
    },
    {
      name: "Submit Button",
      value: projectPublicSettings?.submitBtn || "Submit",
      content: <SubmitBtn project={project} updateSettings={updateSettings} />,
    },
  ];

  return <PanelTemplate pages={pages} />;
});
