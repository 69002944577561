import styled from "styled-components";

export const Block = styled.div`
  & > div {
    margin: 12px 0;
  }

  .ls-contentEditable {
    display: block;
    border: 1px solid transparent;
    padding: 8px 16px;
    border-radius: 4px;
    width: 100%;
    font-family: inherit;
    font-size: 1.17rem;
    line-height: 1.5rem;
    box-sizing: border-box;
    resize: none;
    background: transparent;
  }
`;

export const TitleText = styled.div`
  .ls-contentEditable {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
  }
`;

export const Field = styled.div`
  .ls-contentEditable {
    /*background: #f4f1f1;*/
  }
`;

export const DescText = styled.div``;

export const RadioOptions = styled.div`
  h4 {
    font-weight: 600;
  }
  .ls-field {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .ls-contentEditable {
      border-color: #d4c3c3;
    }
    .radio-demo {
      position: relative;
      border: 1px solid #94c0ca;
      border-radius: 8px;
      height: 0;
      width: 0;
      padding: 6px;
    }
    .remove-option {
      display: flex;
      visibility: hidden;
      position: relative;
      height: 20px;
      width: 20px;
      padding: 0;
      align-items: center;
      justify-content: center;
      float: right;
      i {
        margin: 0 !important;
      }
    }
  }

  .ls-field:hover .remove-option {
    display: flex;
    visibility: visible;
  }
`;

export const DropdownOptions = styled.div`
  h4 {
    font-weight: 600;
  }
  .ls-field {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .option-demo {
      position: relative;
      border: 1px solid #d4c3c3;
      border-radius: 20px;
      padding: 2px 8px;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  .selectField {
    grid-column-start: 1;
  }
  .pickerField {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Image = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const Gallery = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 4px;
  flex-wrap: wrap;

  .lf-gallery-item img {
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const Video = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const LayoutBlock = styled.div`
  display: grid;
  grid-template-columns: ${({ splitTemplate }) =>
    splitTemplate === "30"
      ? `calc(30% - 8px) calc(70% - 8px)`
      : splitTemplate === "100"
      ? `100%`
      : splitTemplate === "70"
      ? `calc(70% - 8px) calc(30% - 8px)`
      : "calc(50% - 8px) calc(50% - 8px)"};
  gap: 16px;
`;

export const RichText = styled.div`
  > * {
    margin: 0px !important;
    .ql-size-small {
      font-size: 0.75em;
    }
    .ql-size-large {
      font-size: 1.5em;
    }
    .ql-size-huge {
      font-size: 2.5em;
    }
  }
`;
