import { Form as FormUI, Button, Card, Divider } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { useStore } from "structure";
import { Link } from "react-router-dom";
import { Title, ButtonsWrapper } from "ui/common-styles";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import { min, max, multiValidators, required } from "util/validators";
import * as S from "../styles";
import { FORM_ERROR } from "final-form";

export const Login = () => {
  const { accountStore } = useStore();
  const history = useHistory();
  const [isAdditionalInfoRequired, setIsAdditionalInfoRequired] =
    useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    email: "",
    name: "",
    google_id: "",
    avatar: "",
    username: "",
  });

  const handleLogin = async (data) => {
    const res = await accountStore.login(data);
    if (res.status === "error") {
      return { [FORM_ERROR]: "email and password are not matching" };
    }
    if (res.status === "require_email_verification") {
      history.push("/email-verification");
    } else {
      history.push("/dashboard/");
    }
  };

  const submitCompleteRegistration = async (data) => {
    if (
      await accountStore.registerWithGoogleData({ ...additionalInfo, ...data })
    )
      history.push("/dashboard/");
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const res = await accountStore.loginGoogle(credentialResponse.credential);
    if (res.status === "additional_info_required") {
      setIsAdditionalInfoRequired(true);
      setAdditionalInfo({
        email: res.email,
        name: res.name,
        google_id: res.google_id,
        avatar: res.avatar,
        username: "",
      });
    }
    if (res.user) history.push("/dashboard/");
  };

  return (
    <>
      <Card centered>
        <Card.Content>
          {isAdditionalInfoRequired ? (
            <>
              <Form
                onSubmit={submitCompleteRegistration}
                render={({ handleSubmit, submitting }) => (
                  <FormUI onSubmit={handleSubmit}>
                    <S.GoogleAccountWrapper>
                      <img src={additionalInfo.avatar} alt="avatar" />
                      <div className="ls-account-content">
                        <span>{additionalInfo.email}</span>
                      </div>
                    </S.GoogleAccountWrapper>
                    <FieldText
                      finalFormProps={{
                        name: "name",
                        validate: multiValidators([required, min(2)]),
                        initialValue: additionalInfo.name,
                      }}
                      semanticProps={{
                        disabled: submitting,
                      }}
                      label="Verify your name"
                    />

                    <FieldText
                      finalFormProps={{
                        name: "username",
                        validate: multiValidators([required, min(4)]),
                      }}
                      semanticProps={{
                        disabled: submitting,
                      }}
                      label="Select your username"
                    />

                    <Button
                      type="submit"
                      disabled={submitting}
                      loading={submitting}
                      fluid
                    >
                      Complete registeration
                    </Button>
                  </FormUI>
                )}
              />
            </>
          ) : (
            <>
              <Title>Login</Title>
              <GoogleOAuthProvider clientId="171609862382-nmdm59oogpn3berrpibd135obgeisu4g.apps.googleusercontent.com">
                <div>
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
              </GoogleOAuthProvider>
              <Form
                onSubmit={handleLogin}
                render={({
                  handleSubmit,
                  submitting,
                  submitError,
                  hasSubmitErrors,
                }) => {
                  return (
                    <FormUI onSubmit={handleSubmit}>
                      {hasSubmitErrors && (
                        <div className="ls-submit-error">
                          <p>{submitError}</p>
                        </div>
                      )}

                      <FieldText
                        type="email"
                        finalFormProps={{
                          name: "email",
                          validate: multiValidators([required]),
                        }}
                        semanticProps={{
                          disabled: submitting,
                        }}
                        label="Email"
                      />
                      <FieldText
                        finalFormProps={{
                          name: "password",
                          validate: multiValidators([
                            required,
                            min(8),
                            max(255),
                          ]),
                        }}
                        semanticProps={{
                          type: "password",
                          disabled: submitting,
                        }}
                        label="Password"
                      />
                      <FieldCheckbox
                        name="remember"
                        label="remember me"
                        disabled={submitting}
                      />
                      <Button
                        type="submit"
                        disabled={submitting}
                        loading={submitting}
                      >
                        Submit
                      </Button>
                    </FormUI>
                  );
                }}
              />

              <ButtonsWrapper>
                <Link to="/reset-password">Forget my password</Link>
              </ButtonsWrapper>
              <Divider horizontal>dont have account?</Divider>
              <ButtonsWrapper>
                <Link to="/register">register</Link>
              </ButtonsWrapper>
            </>
          )}
        </Card.Content>
      </Card>
    </>
  );
};
