import { observer } from "mobx-react-lite";
import * as S from "./styles";
import { Button, Pagination } from "semantic-ui-react";
import { useStore } from "structure";
import { Loading } from "components/shared/Loading";
import { useEffect, useState } from "react";
import { formatDate, formatSubmissionDateTime } from "util/helpers";
import { BasicButton } from "ui/common-styles";
import { toast } from "react-toastify";
import { Confirm } from "ui/Confirm";
import { Icon } from "assets/icons/Icon";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { FieldDropdown } from "ui/FieldDropdown";
import { multiply, replace } from "lodash";

export const Submissions = observer(() => {
  const { repliesStore, projectsStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmReplyDeleteId, setConfirmReplyDeleteId] = useState(null);
  const [indexPage, setIndexPage] = useState(1);
  const [response, setResponse] = useState(null);
  const { replies, paginationMeta } = repliesStore;
  const [allProjects, setAllProjects] = useState(null);

  useEffect(() => {
    repliesStore.fetchReplies().then(() => setIsLoading(false));
    projectsStore.fetchAllProjects().then((data) =>
      setAllProjects(
        data.map((item) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        }))
      )
    );
    return () => setIsLoading(true);
    // eslint-disable-next-line
  }, []);

  const handleDeleteReply = async () => {
    await repliesStore.delete(confirmReplyDeleteId);
    toast.success("Reply deleted successfully");
  };

  const showResponse = (item) => {
    setResponse(item);
  };

  useEffect(() => {
    if (!replies) return;
    const data = replies?.[0];
    if (!data) {
      showResponse(null);
      return;
    }
    const response = JSON.parse(data.response);
    showResponse({
      id: data?.id,
      data: response,
      meta: {
        projectId: data.project_id,
        projectName: data.project_name,
        country: data.sender_country,
        time: formatDate(data.submission_time),
      },
    });
  }, [replies]);

  const handleSearch = (data) => {
    console.log(data);

    setIsLoading(true);
    repliesStore.fetchReplies(data).then(() => setIsLoading(false));
  };

  return (
    <S.Wrapper>
      <div className="main-section">
        <h3>Submissions</h3>
        <Form
          onSubmit={handleSearch}
          render={({ handleSubmit, submitting, reset }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FieldText
                  label="Seatch in response content"
                  finalFormProps={{
                    name: "search",
                  }}
                  semanticProps={{
                    placeholder: "Search...",
                    disabled: submitting,
                  }}
                />
                <FieldDropdown
                  label="Selected project"
                  finalFormProps={{
                    name: "project_ids",
                  }}
                  semanticProps={{
                    placeholder: "Search...",
                    disabled: submitting || !allProjects,
                    loading: !allProjects,
                    options: allProjects || [],
                    multiple: true,
                    defaultValue: [],
                  }}
                />
                <div className="ls-form-name-actions">
                  <Button
                    basic
                    type="button"
                    size="small"
                    onClick={() => handleSearch({})}
                  >
                    Reset
                  </Button>
                  <Button primary type="submit" size="small">
                    Search
                  </Button>
                </div>
              </form>
            );
          }}
        />

        {isLoading ? (
          <div style={{ height: 400 }}>
            <Loading />
          </div>
        ) : (
          <>
            <S.PanelWrapper>
              <ul className="ls-results">
                {repliesStore.isLoading ? (
                  <>
                    {[0, 1, 2].map((_) => {
                      return <SubmissionItem key={_} data={null} />;
                    })}
                    <li className="ls-info">Loading page {indexPage} ...</li>
                  </>
                ) : (
                  <>
                    {replies.map((reply) => {
                      return (
                        <SubmissionItem
                          key={reply?.id}
                          data={reply}
                          showResponse={showResponse}
                          isActive={response?.id === reply?.id}
                        />
                      );
                    })}
                    {replies.length > 0 ? (
                      <li className="ls-info">
                        Showing results {paginationMeta.from} -{" "}
                        {paginationMeta.to}
                      </li>
                    ) : (
                      <h4>No results</h4>
                    )}
                  </>
                )}
              </ul>
              <S.SidebarContent>
                {!response ? (
                  <p>No Data</p>
                ) : (
                  <>
                    <ul className="ls-form-response" key={response.id}>
                      {response.data.map((form, idx) => (
                        <li key={idx}>
                          <span>{form.name}</span>
                          <ul>
                            {form.fields.map((field, s_idx) => (
                              <li key={s_idx}>
                                <span>{field.field}</span>
                                <span>{field.value}</span>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <p>
                        Project {response.meta.projectName}{" "}
                        <span>ID: {response.meta.projectId}</span>
                      </p>
                      <p>
                        Submitted at {response.meta.time} from{" "}
                        {response.meta.country}
                      </p>
                    </div>
                  </>
                )}
              </S.SidebarContent>
            </S.PanelWrapper>
            {paginationMeta.last_page && (
              <Pagination
                activePage={indexPage}
                totalPages={paginationMeta.last_page}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={null}
                lastItem={null}
                prevItem={{ content: <Icon name="left" />, icon: true }}
                nextItem={{ content: <Icon name="right" />, icon: true }}
                onPageChange={(e, data) => {
                  setIndexPage(data.activePage);
                  repliesStore.fetchReplies({ page: data.activePage });
                }}
              />
            )}
          </>
        )}
      </div>

      <Confirm
        content="Are you sure you want to pernently delete this reply?"
        open={!!confirmReplyDeleteId}
        onCancel={() => setConfirmReplyDeleteId(null)}
        onConfirm={handleDeleteReply}
        color="red"
        size="tiny"
      />
    </S.Wrapper>
  );
});

const SubmissionItem = ({ data, showResponse, isActive }) => {
  if (!data) {
    return <S.SubmissionItemWrapper>PLACEHOLDER</S.SubmissionItemWrapper>;
  }
  const response = JSON.parse(data.response);
  const replyName = response?.[0]?.fields?.find(
    (elem) =>
      elem.field.toLowerCase() === "name" ||
      elem.field.toLowerCase() === "first name" ||
      elem.field.toLowerCase() === "full name"
  );
  const replyLastName = response?.[0]?.fields?.find(
    (elem) => elem.field.toLowerCase() === "last name"
  );
  const submissionTime = formatSubmissionDateTime(data.submission_time);
  return (
    <S.SubmissionItemWrapper className={isActive ? "ls-active" : ""}>
      <BasicButton
        onClick={() =>
          showResponse({
            id: data.id,
            data: response,
            meta: {
              projectId: data.project_id,
              projectName: data.project_name,
              country: data.sender_country,
              time: formatDate(data.submission_time),
            },
          })
        }
      >
        <h5>
          {replyName?.value
            ? replyName.value + (replyLastName?.value || "")
            : "New Reply"}
        </h5>
        <div className="ls-details">
          <span>{data.project_name}</span>
          <span>{submissionTime}</span>
        </div>
      </BasicButton>
    </S.SubmissionItemWrapper>
  );
};
