import { Link } from "react-router-dom";
import * as S from "./styles";

export const Pricing = () => {
  return (
    <S.PricingWrapper>
      <h1>Pricing Plans</h1>
      <p>Find the perfect plan for your business needs.</p>
      <ul className="ls-plans">
        {/* <li>
          <h3>Light Plan</h3>
          <p>
            Begin your journey with our limited free plan. Experience the
            essentials of creating forms and landing pages with LightForm.
          </p>
          <div>
            <h5>Features:</h5>
            <ul>
              <li>Basic form and landing page creation</li>
              <li>Access to the dashboard for viewing replies</li>
            </ul>
          </div>
          <Button>Free</Button>
          <span>No card needed</span>
        </li> */}
        <li>
          <h3>Premium Plan</h3>
          <p>
            Elevate your business with our Premium plan. Enjoy advanced features
            and full customization to meet your needs.
          </p>
          <div>
            <h5>Features:</h5>
            <ul>
              <li>Advanced form and landing page creation</li>
              <li>Full customization options</li>
              <li>Access to premium templates</li>
              <li>Enhanced analytics and reporting</li>
              <li>Priority customer support</li>
            </ul>
          </div>
          <Link to="/payment/premium">$14/month</Link>
          <span>or $147/year (12% off)</span>
        </li>
        <li>
          <h3>Pro Plan</h3>
          <p>
            Unlock the full potential of LightForm with our Pro plan. Ideal for
            businesses that require top-tier features and dedicated support.
          </p>
          <div>
            <h5>Features:</h5>
            <ul>
              <li>All Premium Plan features</li>
              <li>Unlimited form submissions</li>
              <li>Integration with third-party tools</li>
              <li>Advanced user management</li>
              <li>Dedicated account manager</li>
            </ul>
          </div>
          <Link to="/payment/pro">$36/month</Link>
          <span>or $380/year (12% off)</span>
        </li>
      </ul>
    </S.PricingWrapper>
  );
};
