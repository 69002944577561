import { Button } from "semantic-ui-react";

export const Hero = () => {
  return (
    <div className="ls-hero">
      <h1>Shape Your Digital Future</h1>
      <p>
        Empower your organization with custom forms and landing pages that
        transform data collection into a smooth, efficient process.
      </p>
      <Button>Start with Us Now</Button>
    </div>
  );
};
