import styled from "styled-components";

export const Block = styled.div`
  & > div {
    margin: 12px 0;
  }

  .ls-contentEditable {
    display: block;
    border: 1px solid transparent;
    padding: 8px 16px;
    border-radius: 4px;
    width: 100%;
    font-family: inherit;
    font-size: 1.17rem;
    line-height: 1.5rem;
    box-sizing: border-box;
    resize: none;
    background: transparent;
  }
  .ls-contentEditable:hover {
    border-color: #d4c3c3;
  }
  .ls-contentEditable:focus-visible {
    outline: 0px;
    border-color: #a47c7c;
  }
  .ls-field {
    .ls-contentEditable:hover {
      border-color: #94c0ca;
    }
    .ls-contentEditable:focus-visible {
      border-color: #31a5be;
    }
  }
`;

export const TitleText = styled.div`
  .ls-contentEditable {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
  }
`;

export const Field = styled.div`
  .ls-contentEditable {
    /*background: #f4f1f1;*/
  }

  .ls-field {
    background: #f4f1f1;
  }
`;

export const DescText = styled.div``;

export const RadioOptions = styled.div`
  h4 {
    font-weight: 600;
  }
  .ls-field {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .ls-contentEditable {
      border-color: #d4c3c3;
    }
    .radio-demo {
      position: relative;
      border: 1px solid #94c0ca;
      border-radius: 8px;
      height: 0;
      width: 0;
      padding: 6px;
    }
    .remove-option {
      display: flex;
      visibility: hidden;
      position: relative;
      height: 20px;
      width: 20px;
      padding: 0;
      align-items: center;
      justify-content: center;
      float: right;
      i {
        margin: 0 !important;
      }
    }
  }

  .ls-field:hover .remove-option {
    display: flex;
    visibility: visible;
  }
`;

export const DropdownOptions = styled.div`
  h4 {
    font-weight: 600;
  }
  .ls-field {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .ls-contentEditable {
      border-color: #d4c3c3;
    }
    .option-demo {
      position: relative;
      border: 1px solid #d4c3c3;
      border-radius: 20px;
      padding: 2px 8px;
    }
    .remove-option {
      display: flex;
      visibility: hidden;
      position: relative;
      height: 20px;
      width: 20px;
      padding: 0;
      align-items: center;
      justify-content: center;
      float: right;
      i {
        margin: 0 !important;
      }
    }
  }

  .ls-field:hover .remove-option {
    display: flex;
    visibility: visible;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  .selectField {
    grid-column-start: 1;
  }
  .pickerField {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Image = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 4px;

  .ls-input-element {
    border: 1px solid transparent;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .ls-input-element:hover {
    border-color: #94c0ca;
  }
  .ls-input-element:focus-visible {
    border-color: #31a5be;
  }
`;

export const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border-radius: 4px;
`;

export const SplitWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ splitTemplate, gap }) =>
    splitTemplate === 30
      ? `3fr 7fr`
      : splitTemplate === 100
      ? `1fr`
      : splitTemplate === 70
      ? `7fr 3fr`
      : "1fr 1fr"};
  gap: ${({ gap }) => gap || "16px"};

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const RichText = styled.div`
  .ql-container {
    font-family: var(--primary-font);
    font-size: 16px;
  }
`;
