import { Field } from "react-final-form";
import { FormField, TextAreaWrapper } from "./common-styles";
import ReactTextareaAutosize from "react-textarea-autosize";

export const FieldTextArea = ({ label, finalFormProps, semanticProps }) => {
  return (
    <Field {...finalFormProps}>
      {({ meta, input }) => {
        console.log(meta);

        return (
          <FormField>
            <label>{label}</label>
            <TextAreaWrapper>
              <ReactTextareaAutosize
                name={input.name}
                value={input.value}
                onChange={input.onChange}
                placeholder="Type description ..."
                minRows={4}
                {...semanticProps}
              />
            </TextAreaWrapper>
            {meta.touched &&
              (meta.error?.[input.name] || meta.submitError?.[input.name]) && (
                <p className="ls-error">
                  {meta.error?.[input.name] || meta.submitError?.[input.name]}
                </p>
              )}
          </FormField>
        );
      }}
    </Field>
  );
};
