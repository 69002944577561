import * as S from "./styles";

export const Title = ({ data }) => {
  return (
    <S.Block>
      {data.title && <h2>{data.title}</h2>}
      {data.desc && <p>{data.desc}</p>}
    </S.Block>
  );
};
