import { NavLink, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import * as S from "./styles";
import { ReactComponent as Logo } from "assets/icons/logo_color.svg";
import { Icon } from "assets/icons/Icon";

export const Navigation = observer(() => {
  const history = useHistory();
  const location = useLocation();

  const isProjectActive = () => {
    return (
      location.pathname.includes("/project") ||
      location.pathname.includes("/form")
    );
  };

  return (
    <S.SideNavigation>
      <button
        className="ls-as-link"
        onClick={() => history.push("/dashboard/")}
      >
        <Logo height={40} />
        creative sparks
      </button>
      <ul>
        <li>
          <NavLink
            to="/dashboard"
            isActive={(match, location) =>
              location.pathname === "/dashboard" ||
              location.pathname === "/dashboard/"
            }
          >
            <Icon name="home" />
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard/projects"
            isActive={(match, location) => match || isProjectActive()}
          >
            <Icon name="tactic" />
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard/media">
            <Icon name="gallery" />
            Media
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard/submissions">
            <Icon name="send" />
            Submissions
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard/statistics">
            <Icon name="chartBar" />
            Statistics
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard/settings">
            <Icon name="settings" />
            Settings
          </NavLink>
        </li>
      </ul>
    </S.SideNavigation>
  );
});
