import { Title as TitleElement } from "./Elements/Title";
import { Text as TextElement } from "./Elements/Text";
import { Number as NumberElement } from "./Elements/Number";
import { Email as EmailElement } from "./Elements/Email";
import { TextArea as TextAreaElement } from "./Elements/TextArea";
import { RadioGroup as RadioGroupElement } from "./Elements/RadioGroup";
import { Dropdown as DropdownElement } from "./Elements/Dropdown";
import { Checkbox as CheckboxElement } from "./Elements/Checkbox";
import { Date as DateElement } from "./Elements/Date";
import { Image as ImageElement } from "./Elements/Image";
import { Gallery as GalleryElement } from "./Elements/Gallery";
import { Video as VideoElement } from "./Elements/Video";
import { RichText as RichTextElement } from "./Elements/RichText";
import { Laytout } from "./Elements/Laytout";
import * as S from "./styles";
import { AsText } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

export const Block = ({ type, blockId, localStore, defaultValues }) => {
  const remove = () => {
    localStore.remove(`block-${blockId}`);
  };

  const duplicate = () => {
    localStore.duplicate(`block-${blockId}`);
  };

  const switchInnerBlocks = () => {
    localStore.switchInnerBlocks(`block-${blockId}`);
  };

  const initialValues =
    defaultValues?.values?.[defaultValues.originalId]?.[type] || {};

  const isLayout = type === "tool-13";

  if (isLayout) {
    console.log(defaultValues?.values?.[defaultValues.originalId]?.[type]);
    return (
      <S.Wrapper key={blockId}>
        <S.ButtonOps>
          {/* <span>blockId: {blockId}</span> */}
          <AsText type="button" title="switch" onClick={switchInnerBlocks}>
            <Icon name="switch" />
            switch
          </AsText>
          <AsText type="button" title="duplicate" onClick={duplicate}>
            <Icon name="copy" />
            duplicate
          </AsText>
          <AsText type="button" title="remove" onClick={remove}>
            <Icon name="delete" />
            remove
          </AsText>
        </S.ButtonOps>
        <S.Block>
          <Laytout
            blockId={blockId}
            localStore={localStore}
            defaultValues={defaultValues}
            initialValues={initialValues}
          />
        </S.Block>
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper key={blockId}>
      <S.ButtonOps>
        {/* <span>blockId: {blockId}</span> */}
        <AsText type="button" title="duplicate" onClick={duplicate}>
          <Icon name="copy" />
          duplicate
        </AsText>
        <AsText type="button" title="remove" onClick={remove}>
          <Icon name="delete" />
          remove
        </AsText>
      </S.ButtonOps>
      <S.Block>
        {type === "empty" && <p>add a new block</p>}
        {type === "tool-1" && (
          <TitleElement blockId={blockId} initialValues={initialValues} />
        )}
        {type === "tool-2" && (
          <TextElement blockId={blockId} initialValues={initialValues} />
        )}
        {type === "tool-3" && (
          <TextAreaElement blockId={blockId} initialValues={initialValues} />
        )}
        {type === "tool-4" && (
          <RadioGroupElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-5" && (
          <DropdownElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-6" && (
          <CheckboxElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-7" && (
          <DateElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-8" && (
          <EmailElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-9" && (
          <NumberElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-10" && (
          <ImageElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-11" && (
          <GalleryElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-12" && (
          <VideoElement blockId={blockId} initialValues={initialValues} />
        )}

        {type === "tool-14" && (
          <RichTextElement blockId={blockId} initialValues={initialValues} />
        )}
      </S.Block>
    </S.Wrapper>
  );
};
