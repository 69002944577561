import { Container } from "./styles";
import { Header } from "./parts/Header";
import { Footer } from "./parts/Footer";

export const Outlet = ({ children }) => {
  return (
    <Container>
      <Header />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};
