import { Account } from "./stores/account";
import { makeObservable, observable, action } from "mobx";
import { Projects } from "./stores/projects";
import { View } from "./stores/view";
import i18n from "i18next";
import { Media } from "./stores/media";
import { Replies } from "./stores/replies";
import { Payment } from "./stores/payment";

class RootStore {
  isFirstLoading = true;
  lang = 1;

  constructor() {
    this.accountStore = new Account(this);
    this.paymentStore = new Payment(this);
    this.projectsStore = new Projects(this);
    this.repliesStore = new Replies(this);
    this.mediaStore = new Media(this);
    this.viewStore = new View(this);

    //fetch first loading
    this.fitstLoading();

    makeObservable(this, {
      isFirstLoading: observable,
      lang: observable,
      fitstLoading: action,
      changeLanguage: action,
    });
  }

  changeLanguage(lang) {
    if (lang) {
      this.lang = lang;
      i18n.changeLanguage(lang === 2 ? "he" : "en");
    }
  }

  async fitstLoading() {
    // for now until we have IL translation:::
    // fetch("https://ipapi.co/json/")
    //   .then((res) => res.json())
    //   .then((response) => {
    //     if (response.country === "IL") this.changeLanguage(2);
    //   });
    // await Promise.all([this.projectsStore.fetchProjects()]);
    this.isFirstLoading = false;
  }
}

const rootStore = new RootStore();

export const useStore = () => {
  return rootStore;
};
