import { Field } from "react-final-form";
import ReactQuill from "react-quill";
import * as S from "./styles";
import { useState } from "react";

const toolbarOptions = [
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline"],
  ["link", "blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //[{ font: [] }],
  [{ align: [] }],
];

export const RichText = ({ blockId, initialValues }) => {
  const [editorValue, setEditorValue] = useState(initialValues?.editor || "");
  console.log(editorValue);

  return (
    <S.Block>
      <Field name={`${blockId}.tool-14.editor`} initialValue={editorValue}>
        {(props) => (
          <S.RichText>
            <input hidden name={props.input.name} value={editorValue} />
            <ReactQuill
              value={editorValue}
              onChange={(value) => setEditorValue(value)}
              modules={{
                toolbar: toolbarOptions,
              }}
              theme="snow"
            />
          </S.RichText>
        )}
      </Field>
    </S.Block>
  );
};
