import { Form as FormUI, Button, Card } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { useStore } from "structure";
import { Title } from "ui/common-styles";
import { multiValidators, required, min, max } from "util/validators";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const EmailVerification = () => {
  const { accountStore } = useStore();
  const history = useHistory();
  const { loginDetails } = accountStore;

  useEffect(() => {
    if (!loginDetails) {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, []);

  const handleResendEmail = async () => {
    const res = await accountStore.requestVerifyEmail(loginDetails.email);
    if (res.error) {
      return res;
    } else {
      toast.success("Verification code sent");
    }
  };

  const submit = async (data) => {
    const res = await accountStore.verifyEmail({
      email: loginDetails.email,
      code: data.code,
    });
    if (res.error) {
      return { code: res.error };
    }
    history.push("/dashboard/");
  };

  return (
    <>
      <Card centered>
        <Link to="/login"> back </Link>
        <Card.Header>
          <Title>Email Verification</Title>
        </Card.Header>
        <Card.Content>
          <Form
            onSubmit={submit}
            render={({ handleSubmit, submitting }) => (
              <FormUI onSubmit={handleSubmit}>
                <div>
                  <p>The verification code sent to {loginDetails.email}</p>
                  <p>Didn't recieve it?</p>
                  <Button type="button" basic onClick={handleResendEmail}>
                    Send again
                  </Button>
                </div>
                <FieldText
                  finalFormProps={{
                    name: "code",
                    validate: multiValidators([required, min(6), max(6)]),
                  }}
                  semanticProps={{
                    disabled: submitting,
                  }}
                  label="Enter code"
                />

                <Button
                  type="submit"
                  disabled={submitting}
                  loading={submitting}
                  fluid
                >
                  Verify
                </Button>
              </FormUI>
            )}
          />
        </Card.Content>
      </Card>
    </>
  );
};
