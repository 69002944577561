import { Form as FormUI, Button, Card, Divider } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { useStore } from "structure";
import { Link } from "react-router-dom";
import { Title, ButtonsWrapper } from "ui/common-styles";
import {
  multiValidators,
  required,
  min,
  max,
  equals,
  requiredCheck,
} from "util/validators";
import { FieldCheckbox } from "ui/FieldCheckbox";

export const Register = () => {
  const { accountStore } = useStore();
  const history = useHistory();

  const submit = async (data) => {
    const res = await accountStore.register(data);
    if (res.error || res.errors) {
      return res.error || res.errors;
    }
    history.push("/email-verification");
  };

  return (
    <>
      <Card centered>
        <Card.Header>
          <Title>Register</Title>
        </Card.Header>
        <Card.Content>
          <Form
            onSubmit={submit}
            render={({ handleSubmit, submitting }) => (
              <FormUI onSubmit={handleSubmit}>
                <FieldText
                  finalFormProps={{
                    name: "username",
                    validate: multiValidators([required, min(4)]),
                  }}
                  semanticProps={{
                    disabled: submitting,
                  }}
                  label="Username"
                />

                <FieldText
                  finalFormProps={{
                    name: "email",
                    validate: multiValidators([required, min(6), max(255)]),
                  }}
                  semanticProps={{
                    disabled: submitting,
                    type: "email",
                  }}
                  label="Email"
                />
                <FieldText
                  finalFormProps={{
                    name: "name",
                    validate: multiValidators([required, min(2)]),
                  }}
                  semanticProps={{
                    disabled: submitting,
                  }}
                  label="Full Name"
                />
                <FieldText
                  finalFormProps={{
                    name: "password",
                    validate: multiValidators([required, min(8), max(255)]),
                  }}
                  semanticProps={{
                    disabled: submitting,
                    type: "password",
                  }}
                  label="Password"
                />
                <FieldText
                  finalFormProps={{
                    name: "password_confirmation",
                    validate: multiValidators([
                      required,
                      min(8),
                      max(255),
                      equals("password"),
                    ]),
                  }}
                  semanticProps={{
                    disabled: submitting,
                    type: "password",
                  }}
                  label="password again"
                />
                <FieldCheckbox
                  label="I read and agree to term of use"
                  finalFormProps={{
                    name: "agree",
                    validate: requiredCheck,
                  }}
                  semanticProps={{
                    disabled: submitting,
                  }}
                />
                <Button
                  type="submit"
                  disabled={submitting}
                  loading={submitting}
                >
                  Submit
                </Button>
              </FormUI>
            )}
          />
          <Divider horizontal>already registered?</Divider>
          <ButtonsWrapper>
            <Link to="/login"> login </Link>
          </ButtonsWrapper>
        </Card.Content>
      </Card>
    </>
  );
};
