import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const DraggableBlock = ({ block }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `block-${block.id}`,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div ref={setNodeRef} style={style} className="block">
      <div className="block-drag">
        <button {...attributes} {...listeners}>
          ⣿
        </button>
      </div>
      {block.element}
    </div>
  );
};

export const BlockItem = ({ block }) => {
  if (block?.element) {
    return (
      <div className="block active-drag">
        <div className="block-drag">
          <button>⣿</button>
        </div>
        {block.element}
      </div>
    );
  }
  console.error("not block", block);
  return null;
};
