import { Grid, Icon } from "semantic-ui-react";

export const Features = () => {
  return (
    <div className="ls-features">
      <h2>Features</h2>
      <Grid columns={3} divided stackable>
        <Grid.Row>
          <Grid.Column>
            <Icon name="edit" size="huge" />
            <h3>Drag-and-Drop Interface</h3>
            <p>
              Design forms and pages effortlessly with our intuitive
              drag-and-drop builder. No coding required—just your creativity.
            </p>
          </Grid.Column>
          <Grid.Column>
            <Icon name="file" size="huge" />
            <h3>Customizable Design</h3>
            <p>
              Align your forms and landing pages with your brand. Easily add
              photos, choose colors, and match your product’s style.
            </p>
          </Grid.Column>
          <Grid.Column>
            <Icon name="chart line" size="huge" />
            <h3>Analytics</h3>
            <p>Gain insights with detailed analytics and reporting.</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Icon name="plug" size="huge" />
            <h3>Integrations</h3>
            <p>Seamlessly integrate with your favorite tools.</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
