import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Dropdown, Checkbox } from "semantic-ui-react";
import * as S from "./styles";

const typeOptions = [
  {
    key: "text",
    text: "Text",
    value: "text",
  },
  {
    key: "number",
    text: "Number",
    value: "number",
  },
  {
    key: "email",
    text: "Email",
    value: "email",
  },
];

export const Number = ({ blockId, initialValues }) => {
  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-2.field`}
        initialValue={initialValues.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder="field text"
          />
        )}
      </Field>
      <Field
        name={`${blockId}.tool-2.desc`}
        initialValue={initialValues.desc || ""}
      >
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="description (optional)"
            />
          </S.Field>
        )}
      </Field>
      <Field
        name={`${blockId}.tool-2.placeholder`}
        initialValue={initialValues.placeholder || ""}
      >
        {(props) => (
          <S.Field className="ls-field">
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="placeholder (optional)"
            />
          </S.Field>
        )}
      </Field>
      <Field name={`${blockId}.tool-2.required`} value={initialValues.required}>
        {(props) => (
          <S.Field>
            <Checkbox
              name={props.input.name}
              checked={props.input.value || false}
              onChange={(e, data) => {
                props.input.onChange(data.checked);
              }}
              label="Required"
            />
          </S.Field>
        )}
      </Field>
      <Field
        name={`${blockId}.tool-2.type`}
        initialValue={initialValues.type || ""}
      >
        {(props) => (
          <S.Field>
            <label>Field type</label>
            <Dropdown
              name={props.input.name}
              value={props.input.value}
              onChange={(e, data) => {
                props.input.onChange(data.value);
              }}
              placeholder="Select Friend"
              fluid
              search
              selection
              options={typeOptions}
            />
          </S.Field>
        )}
      </Field>
    </S.Block>
  );
};
