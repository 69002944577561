import { Wrapper } from "./styles";

export const Loading = ({ text }) => {
  return (
    <Wrapper>
      {text && <p>{text}</p>}
      <div className="loader"></div>
    </Wrapper>
  );
};
