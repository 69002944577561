import { useHistory, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import * as S from "./styles";
import { Dropdown, Search } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";

export const Header = observer(() => {
  const history = useHistory();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  const logout = async () => {
    await accountStore.logout();
    history.push("/login");
  };

  return (
    <S.Container>
      <S.Wrapper>
        <Search
          // loading={loading}
          placeholder="Search project..."
          onResultSelect={(e, data) =>
            console.log({ selection: data.result.title })
          }
          // onSearchChange={handleSearchChange}
          // results={results}
          // value={value}
        />
      </S.Wrapper>
      <S.Wrapper>
        <Dropdown
          trigger={
            <div className="username-btn">
              <Icon name="accountCircle" />
              <span>{currentAccount?.username}</span>
            </div>
          }
          loading={currentAccount?.isLoading}
          floating
          direction="left"
          icon={null}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text="profile"
              icon={<Icon name="profile" />}
              as={Link}
              to="/"
            />
            <Dropdown.Item
              text="Help"
              icon={<Icon name="help" />}
              as={Link}
              to="/"
            />
            <Dropdown.Item
              text="Sign Out"
              icon={<Icon name="signOut" />}
              as={S.DropdownButton}
              onClick={logout}
            />
          </Dropdown.Menu>
        </Dropdown>
      </S.Wrapper>
    </S.Container>
  );
});
