import { useHistory } from "react-router-dom";
import { FormPreview } from "./FormPreview.jsx";
import { ButtonWithLink, GeneralEdit } from "ui/common-styles";

import { toast } from "react-toastify";
import { Icon } from "assets/icons/Icon";
import { useStore } from "structure";
import { Button } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Confirm } from "ui/Confirm";
import * as S from "./styles";

export const Forms = observer(({ lcoalStore, project }) => {
  const history = useHistory();
  const projectId = project?.id;
  const { projectsStore } = useStore();
  const freeBlockNavigation = useRef(false);
  const baseOrder = useRef(JSON.stringify(lcoalStore.order));
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [hasChanges, setHasChanges] = useState(null);

  useEffect(() => {
    setHasChanges(JSON.stringify(lcoalStore.order) !== baseOrder.current);
  }, [lcoalStore.order]);

  useEffect(() => {
    baseOrder.current = JSON.stringify(lcoalStore.order);
    //handle leave page
    const handleBeforeUnload = (e) => {
      if (JSON.stringify(lcoalStore.order) !== baseOrder.current) {
        const confirmationMessage =
          "You have unsaved changes in your project forms. Are you sure you want to leave?";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const unblock = history.block((nextLocation) => {
      if (
        JSON.stringify(lcoalStore.order) !== baseOrder.current &&
        !freeBlockNavigation.current
      )
        return handleBlockedNavigation(nextLocation.pathname);
      return true;
    });
    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line
  }, [projectId]); //may need change

  const handleBlockedNavigation = (nextLocation) => {
    setShowSaveChangesModal(true);
    setNextLocation(nextLocation);
    return false;
  };

  const handleStay = () => {
    setNextLocation(null);
    setShowSaveChangesModal(false);
  };

  const handleOutWithoutSaving = () => {
    freeBlockNavigation.current = true;
    history.push(nextLocation);
  };

  const createNewForm = async () => {
    const newId = await projectsStore.createNewForm(projectId);
    if (newId) {
      toast.success("New form successfully created");
      history.push(`/dashboard/project/${projectId}/form/${newId}`);
    } else {
      toast.error("Error while creating form");
    }
  };

  const saveOrderHandler = () => {
    projectsStore.saveFormsOrder(project, lcoalStore.order);
  };

  return (
    <GeneralEdit>
      <h4>Forms</h4>
      <div className="ls-main-block">
        <div className="ls-header">
          <ButtonWithLink basic onClick={createNewForm}>
            <Icon name="plus" height={20} width={20} />
            Create new form
          </ButtonWithLink>
          <Button disabled={!hasChanges} onClick={saveOrderHandler}>
            Save changes
          </Button>
        </div>
        <S.FormsWrapper>
          {lcoalStore.order.map((item, idx) => {
            if (!item?.form) return null;
            return (
              <FormPreview
                key={item.form.id}
                data={item.form}
                idx={idx}
                projectId={projectId}
                isVisible={item.isVisible}
                lcoalStore={lcoalStore}
              />
            );
          })}
          {lcoalStore.order.length === 0 && <p>NO FORMS YET</p>}
        </S.FormsWrapper>
      </div>

      <Confirm
        header="You have unsaved changes in your project forms"
        content="Are you sure you want to leave?"
        open={showSaveChangesModal}
        onCancel={handleOutWithoutSaving}
        onConfirm={handleStay}
        onClose={handleStay}
        confirmButton="Stay"
        cancelButton="Close without saving"
        size="tiny"
      />
    </GeneralEdit>
  );
});
