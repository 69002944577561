import { MediaGallery } from "./MediaGallery";
import * as S from "./styles";

export const Media = () => {
  return (
    <S.Wrapper>
      <div className="main-section">
        <h3>Media</h3>
      </div>
      <MediaGallery />
    </S.Wrapper>
  );
};
