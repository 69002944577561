import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .main-section {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    h3 {
      font-family: var(--title-font);
      color: var(--primary);
      font-weight: 700;
      font-size: 64px;
    }
  }

  .ui.table {
    border-radius: 8px;
    max-width: 600px;

    thead tr {
      th:first-child {
        border-radius: 8px 0 0 0;
      }
      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  form {
    display: grid;
    grid-template-columns: 200px 200px 200px;
    gap: 24px;
    align-items: end;
    margin-bottom: 24px;

    > div {
      margin-bottom: 0;
    }
  }

  .ls-actions {
    display: flex;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const SidebarContent = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid;
  margin: 16px 16px 16px 0;
  border-radius: 4px;

  > div {
    padding: 48px 24px;
    margin: -48px -24px;
    background: var(--gray-200);

    border-radius: 0 0 5px 5px;
  }

  .ls-form-response {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    gap: 8px;
    overflow: auto;
    flex-direction: column;
    animation: fadeIn 0.3s;

    > li {
      > span {
        font-size: 1.1em;
        font-weight: 700;
      }
      > ul {
        padding-inline-start: 0;

        > li {
          display: flex;
          gap: 16px;
          > span:first-child {
            font-weight: 500;
            min-width: 100px;
            max-width: 180px;
          }
          > span:last-child {
            min-width: 200px;
            max-width: 400px;
          }
        }
      }
      :not(:last-child) {
        padding-bottom: 8px;
        border-bottom: 1px solid var(--gray-300);
      }
    }
  }
`;

export const PanelWrapper = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  background: white;
  border-radius: 8px;
  min-height: 792px;
  margin-bottom: 16px;

  .ls-results {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px;
    padding-inline-start: 0;
    list-style-type: none;

    .ls-info {
      border-top: 1px solid var(--gray-500);
      padding-top: 4px;
      margin-top: 16px;
    }
  }
`;

export const SubmissionItemWrapper = styled.div`
  &.ls-active button {
    background: var(--tertiary);
  }

  button {
    background: var(--gray-200);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    border-radius: 4px;
    gap: 8px;

    h5 {
      font-size: 1.1em;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .ls-details {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;

      span:first-child {
        font-size: 0.9em;
      }

      span:last-child {
        font-size: 0.8em;
      }
    }
  }
`;
