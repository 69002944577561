import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 8px;
  margin: 16px auto;
  width: 100%;
  background: var(--gray-300);
  :focus,
  :focus-within,
  :target,
  :focus-visible,
  :active {
    > div:first-child {
      // background: var(--gray-100);
    }
  }
`;

export const Block = styled.div`
  padding: 20px 44px;
  min-height: 50px;
  border-radius: 8px;
  background: var(--gray-300);
`;

export const ButtonOps = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  height: 0px;
  padding: 20px;
  opacity: 1;
  transition: 0.4s;
`;
