export function makeid(len = 5) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result + Date.now();
}

export function formatDate(initialDate, type) {
  let date = new Date(initialDate);

  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  let hour = "" + date.getHours();
  let min = "" + date.getMinutes();

  if (hour.length < 2) hour = "0" + hour;
  if (min.length < 2) min = "0" + min;

  if (type === "date") return day + "/" + month + "/" + year;

  return day + "/" + month + "/" + year + " " + hour + ":" + min;
}

export function formatSystemDate(initialDate, type) {
  let date = new Date(initialDate);
  if (type === "date") {
    let month = "" + (date.getMonth() + 1);
    let day = "" + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  if (type === "datetime")
    return (
      date.getFullYear() +
      "/" +
      date.getMonth() +
      "/" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
}

export const calcSize = (data) => {
  if (data.size === "px") return [data.height || 300, data.width || 300];
  if (data.size === "percent")
    return [`${data.height || 50}%`, `${data.width || 50}%`];
  return [300, 300];
};

export const isDeepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key]))
      return false;
  }

  return true;
};

export const unwrapProxies = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return primitive values or null unchanged
  }

  // Check if obj is a proxy and unwrap if necessary
  if (Array.isArray(obj.proxyTarget)) {
    return obj.proxyTarget.map(unwrapProxies); // Unwrap array elements
  } else if (typeof obj === "object") {
    const unwrappedObj = {};
    for (let key in obj) {
      unwrappedObj[key] = unwrapProxies(obj[key]); // Recursively unwrap nested objects
    }
    return unwrappedObj;
  }

  return obj; // Return unchanged if not a proxy
};

export function formatSubmissionDateTime(dateTimeStr) {
  const inputDate = new Date(dateTimeStr);
  const today = new Date();

  const inputDateCopy = new Date(inputDate);
  const todayCopy = new Date(today);

  inputDateCopy.setHours(0, 0, 0, 0);
  todayCopy.setHours(0, 0, 0, 0);

  const isToday = inputDateCopy.getTime() === todayCopy.getTime();

  const hours = String(inputDate.getHours()).padStart(2, "0");
  const minutes = String(inputDate.getMinutes()).padStart(2, "0");

  if (isToday) {
    return `${hours}:${minutes}`;
  } else {
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const year = String(inputDate.getFullYear()).slice(-2);
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}
