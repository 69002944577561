import { makeObservable } from "mobx";
import { payment as apiPayment } from "../endpoints";

export class Payment {
  constructor() {
    makeObservable(this, {});
  }

  subscribe(data) {
    return apiPayment.createSubscription({
      plan_id: data.planId,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
    });
  }
}
