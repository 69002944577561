import styled from "styled-components";

export const SideNavigation = styled.aside`
  height: calc(100% - 48px);
  width: 256px;
  margin: 24px;
  padding: 24px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(8px);
  box-shadow: 0px 4px 16px rgba(25, 26, 33, 0.05);

  ul {
    list-style-type: none;
    padding-inline-start: unset;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 32px 0;

    li {
      display: flex;
      width: 100%;
      a {
        display: flex;
        gap: 8px;
        width: 100%;
        padding: 8px 16px;
        font-size: 18px;
        line-height: 24px;
        border-radius: 8px;
        color: var(--gray-900);

        & > svg {
          transition: 0.2s;
        }

        &:hover > svg {
          transform: scale(1.1);
          margin-right: 12px;
        }

        &.active {
          color: var(--gray-100);
          background: var(--gray-900);
          font-weight: 700;

          > svg {
            fill: var(--gray-100);
          }
        }
      }
    }
  }
`;
