import {
  useDndMonitor,
  DragOverlay,
  defaultDropAnimation,
} from "@dnd-kit/core";
import { useStore } from "structure";
import { useParams, useHistory, Link } from "react-router-dom";
import { arrayMove } from "@dnd-kit/sortable";
import { Block } from "components/shared/creationComponents/Block";
import * as S from "./styles";
import { Form } from "react-final-form";
import { Button } from "semantic-ui-react";
import { Confirm } from "ui/Confirm";
import { Observer, observer, useLocalObservable } from "mobx-react-lite";
import {
  BlockItem,
  Droppable,
  Draggable,
} from "components/shared/creationComponents/DNDComponents";
import { TOOLS } from "util/consts";
import { makeid, isDeepEqual } from "util/helpers";
import arrayMutators from "final-form-arrays";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Icon } from "assets/icons/Icon";
import { AsText } from "ui/common-styles";
import { DuplicateFormModal } from "./DuplicateFormModal";
import { FieldText } from "ui/FieldText";
import { NoData } from "components/shared/NoData";
import { Loading } from "components/shared/Loading";
import { InnerBlock } from "components/shared/creationComponents/InnerBlock";

export const Interface = observer(() => {
  const history = useHistory();
  const { projectId, formId } = useParams();
  const { projectsStore } = useStore();
  const [project, setProject] = useState(null);
  const [form, setForm] = useState(null);
  const [isNotFind, setIsNotFind] = useState(false);
  const [isEditFormName, setIsEditFormName] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const freeBlockNavigation = useRef(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    projectsStore
      .getById(projectId)
      .then((project) => {
        const form =
          project?.settings?.order?.find(
            (element) => element.form.id === formId
          )?.form || null;
        if (project && form) {
          setProject(project);
          setForm(form);
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setIsNotFind(true);
      });
    // eslint-disable-next-line
  }, [formId]);

  const localStore = useLocalObservable(() => ({
    isLoading: true,
    formName: form?.name || "UNTITLED FORM",
    blocks: [],
    activeElementIndex: null,
    hasChanges: true,

    get activeBlock() {
      return this.blocks[this.activeElementIndex];
    },

    setIsLoading(bool) {
      this.isLoading = bool;
    },

    setHasChanges(bool) {
      this.hasChanges = bool;
    },

    getBlockIndex(blockId) {
      return this.blocks.findIndex((block) => `block-${block.id}` === blockId);
    },

    setActiveElement(blockId) {
      const newIndex = this.getBlockIndex(blockId);
      this.activeElementIndex = newIndex !== -1 ? newIndex : null;
    },

    setFormName(formName) {
      this.formName = formName;
    },

    add(newBlock, blockId = null, isLayout = false) {
      let index =
        blockId && blockId !== "main-board"
          ? this.getBlockIndex(blockId)
          : this.blocks.length;

      const newBlockType = newBlock?.element?.props?.type;

      if ((isLayout && newBlockType !== "tool-13") || index === -1) {
        const layoutIndex = this.blocks.findIndex(
          (elem) =>
            `block-${elem.id}` === blockId ||
            elem?.items?.some((block) => `block-${block.id}` === blockId)
        );
        let updatedBlock = this.blocks[layoutIndex];

        if (updatedBlock?.items?.length > 1) return;

        updatedBlock["items"] = isLayout
          ? [...(updatedBlock.items || []), newBlock]
          : [newBlock, ...(updatedBlock.items || [])];
        this.blocks = [
          ...this.blocks.slice(0, layoutIndex),
          updatedBlock,
          ...this.blocks.slice(layoutIndex + 1),
        ];
      } else {
        this.blocks = [
          ...this.blocks.slice(0, index),
          newBlock,
          ...this.blocks.slice(index),
        ];
      }
    },

    duplicate(blockId) {
      const values = formRef.current?.getState()?.values || {};
      //console.log(values);
      const blockIndex = this.getBlockIndex(blockId);
      if (blockIndex === -1) return;
      const newBlockId = makeid();
      const sourceBlockProps = this.blocks[blockIndex].element?.props;
      if (!sourceBlockProps) {
        console.error("wrong duplicaiton");
        return;
      }
      const blockElement = {
        id: newBlockId,
        deleted: false,
        isLayout: sourceBlockProps.type === "tool-13",
        element: (
          <Block
            type={sourceBlockProps.type}
            blockId={newBlockId}
            localStore={localStore}
            defaultValues={{ originalId: sourceBlockProps.blockId, values }}
          />
        ),
      };
      this.blocks = [
        ...this.blocks.slice(0, blockIndex + 1),
        blockElement,
        ...this.blocks.slice(blockIndex + 1),
      ];

      if (this.blocks[blockIndex].isLayout) {
        const items = this.blocks[blockIndex].items;
        //console.log(items);
        items.forEach((item) => {
          const originalBlockId = blockId.replace("block-", "");
          const itemProps = item?.element?.props;
          //console.log(itemProps);
          const newInnerBlockId = makeid();
          const innerBlockValues = values[originalBlockId]["tool-13"].items;
          this.add(
            {
              id: newInnerBlockId,
              deleted: false,
              isLayout: false,
              parantLayout: newBlockId,
              element: (
                <InnerBlock
                  type={itemProps.type}
                  blockId={newInnerBlockId}
                  parentLayout={newBlockId}
                  localStore={localStore}
                  defaultValues={{
                    originalId: itemProps.blockId,
                    values: innerBlockValues,
                  }}
                />
              ),
            },
            `block-${newBlockId}`,
            true
          );
        });
      }
    },

    switchInnerBlocks(blockId) {
      const blockIndex = this.getBlockIndex(blockId);
      if (blockIndex === -1 || this.blocks[blockIndex].items.length < 2) return;
      this.blocks[blockIndex].items = [
        this.blocks[blockIndex].items[1],
        this.blocks[blockIndex].items[0],
      ];
    },

    update(blockId, newType) {
      const blockIndex = this.getBlockIndex(blockId);
      if (blockIndex === -1) return;
      const blockElement = {
        ...this.blocks[blockIndex],
        element: (
          <Block type={newType} blockId={blockId} localStore={localStore} />
        ),
      };
      this.blocks = [
        ...this.blocks.slice(0, blockIndex),
        blockElement,
        ...this.blocks.slice(blockIndex + 1),
      ];
    },

    moveBlocks(activeId, overId) {
      const activeIndex = this.getBlockIndex(activeId);
      const overIndex = this.getBlockIndex(overId);
      if (activeIndex !== overIndex) {
        this.blocks = arrayMove(this.blocks, activeIndex, overIndex);
      }
    },

    remove(blockId) {
      const blockIndex = this.getBlockIndex(blockId);
      if (blockIndex === -1) {
        let layout = this.blocks.find((elem) =>
          elem?.items?.some((block) => `block-${block.id}` === blockId)
        );
        if (layout) {
          layout.items = layout?.items?.filter(
            (block) => `block-${block.id}` !== blockId
          );
          const layoutIndex = this.getBlockIndex(`block-${layout.id}`);
          //console.log(layout, layoutIndex);
          this.blocks = [
            ...this.blocks.slice(0, layoutIndex),
            layout,
            ...this.blocks.slice(layoutIndex + 1),
          ];
        }
      } else {
        this.blocks = [
          ...this.blocks.slice(0, blockIndex),
          ...this.blocks.slice(blockIndex + 1),
        ];
      }
    },
    //remove(blockId) { state.count-- },
  }));

  // const handleLeavePage = (path) => () => {
  //   if (localStore.hasChanges) {
  //     return setShowSaveChangesModal(path);
  //   } else {
  //     return history.push(path);
  //   }
  // };

  useDndMonitor({
    onDragStart({ active }) {
      if (active?.id) localStore.setActiveElement(active.id);
    },
    onDragEnd({ active, over }) {
      if (!over?.id || !active?.id) return;
      if (active?.id?.includes("tool-")) {
        const typeName = active?.id;
        if (typeName) addBlock(typeName, over?.id);
      } else if (active?.id?.includes("block-")) {
        localStore.moveBlocks(active.id, over.id);
      }
      localStore.setActiveElement(null);
      //localStore.update(over.id, active.id);
    },
  });

  const addBlock = (typeName, blockId = null, defaultValues = null) => {
    //console.log(defaultValues);
    const isLayout =
      localStore.blocks.find((elem) => `block-${elem.id}` === blockId)
        ?.isLayout || false;

    const newBlockId = makeid();
    localStore.add(
      {
        id: newBlockId,
        deleted: false,
        isLayout: typeName === "tool-13",
        parantLayout: blockId,
        element: isLayout ? (
          <InnerBlock
            type={typeName}
            blockId={newBlockId}
            parentLayout={blockId.replace("block-", "")}
            localStore={localStore}
            defaultValues={defaultValues}
          />
        ) : (
          <Block
            type={typeName}
            blockId={newBlockId}
            localStore={localStore}
            defaultValues={defaultValues}
          />
        ),
      },
      blockId,
      isLayout
    );

    if (typeName === "tool-13" && defaultValues?.originalId === "imported") {
      const items = defaultValues.values.imported["tool-13"].items;
      items.forEach((field) => {
        const typeName = Object.keys(field)?.[0];
        const defaultValues = {
          originalId: "imported",
          values: {
            imported: field,
          },
        };

        //console.log(defaultValues);
        addBlock(typeName, `block-${newBlockId}`, defaultValues);
      });
    }
  };

  const createEditedFormObj = (data) => {
    const blockIds = localStore.blocks.map((block) => block.id);
    const layouts = localStore.blocks.filter((block) => block.isLayout);
    let formData = blockIds.map((id) => {
      let layout = layouts.find((layout) => layout.id === id);
      if (layout) {
        let items = layout.items.map(
          (innerBlock) => data[id]["tool-13"].items[innerBlock.id]
        );
        const { items: _, ...otherProps } = data[id]["tool-13"] || {};
        return {
          "tool-13": {
            items,
            ...otherProps,
          },
        };
      }
      return data[id];
    });

    return {
      ...form,
      name: localStore.formName,
      fields: formData,
    };
  };

  const onSubmit = async (data) => {
    const newFormObj = createEditedFormObj(data);
    const newForm = await projectsStore.saveForm(project, formId, newFormObj);
    if (newForm) {
      setForm(newForm);
      toast.success("Form saved successfully");
    }
  };

  const handleDeleteForm = async () => {
    await projectsStore.deleteForm(projectId, formId).then((success) => {
      if (success) {
        toast.success("Form deleted successfully");
        history.push(`/dashboard/project/${projectId}`);
      }
    });
  };

  const handleSaveForm = async () => {
    return formRef.current?.submit();
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      formRef.current?.submit();
    }
  };

  const changeFormName = async ({ formName }) => {
    const newForm = await projectsStore.saveForm(project, formId, {
      ...form,
      name: formName,
    });
    if (newForm) {
      localStore.setFormName(formName);
      toast.success("Form name changed successfully");
      setIsEditFormName(false);
    }
  };

  const handleBlockedNavigation = (nextLocation) => {
    setShowSaveChangesModal(true);
    setNextLocation(nextLocation);
    return false;
  };

  useEffect(() => {
    if (form) {
      localStore.setFormName(form.name || "Untitled Form");
    }

    if (localStore.blocks.length === 0 && Array.isArray(form?.fields)) {
      form.fields.forEach((field) => {
        const typeName = Object.keys(field)?.[0];
        const defaultValues = {
          originalId: "imported",
          values: {
            imported: field,
          },
        };
        addBlock(typeName, null, defaultValues);
      });
    }
    localStore.setIsLoading(false);

    //handle leave page

    const handleBeforeUnload = (e) => {
      const formData = formRef?.current?.getState()?.values || {};
      const currentForm = createEditedFormObj(formData);
      if (!isDeepEqual(currentForm, form)) {
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave?";
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const unblock = history.block((nextLocation) => {
      const formData = formRef?.current?.getState()?.values || {};
      const currentForm = createEditedFormObj(formData);
      if (!isDeepEqual(currentForm, form) && !freeBlockNavigation.current)
        return handleBlockedNavigation(nextLocation.pathname);
      return true;
    });
    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line
  }, [form]); //may need change

  const handleStay = () => {
    setNextLocation(null);
    setShowSaveChangesModal(false);
  };

  const handleOutWithoutSaving = () => {
    freeBlockNavigation.current = true;
    history.push(nextLocation);
  };

  if (isNotFind) {
    return (
      <NoData
        title="Form not found"
        text="How you get here? brobably a broken link./nfeel free to contact us in case this issue repeat itself."
      />
    );
  }

  if (!project || !form || localStore.isLoading) {
    return <Loading />;
  }

  return (
    <S.PanelWrapper>
      <div className="ls-actions">
        <AsText as={Link} to={`/dashboard/project/${projectId}`} color="black">
          <Icon name="back" />
          back to project {project?.name || "UNTITLED"}
        </AsText>
        <ul>
          <li>
            <AsText onClick={() => setOpenDuplicateModal(true)}>
              <Icon name="copy" />
              Duplicate
            </AsText>
          </li>

          <li>
            <AsText onClick={handleSaveForm} disabled={!localStore.hasChanges}>
              <Icon name="save" />
              Save
            </AsText>
          </li>

          <li>
            <AsText onClick={() => setIsDeleteModalOpen(true)} color="red">
              <Icon name="delete" />
              Delete
            </AsText>
          </li>
        </ul>
      </div>

      <div className="ls-form-title">
        {isEditFormName ? (
          <Form
            onSubmit={changeFormName}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FieldText
                    finalFormProps={{
                      name: "formName",
                      initialValue: localStore.formName,
                    }}
                    semanticProps={{
                      placeholder: "Form name",
                      disabled: submitting,
                    }}
                  />
                  <div className="ls-form-name-actions">
                    <Button
                      basic
                      type="button"
                      size="small"
                      onClick={() => setIsEditFormName(false)}
                    >
                      Cancel
                    </Button>
                    <Button primary type="submit" size="small">
                      Save
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        ) : (
          <>
            <h1>{localStore.formName}</h1>{" "}
            <AsText
              className={isEditFormName ? "ls-active" : ""}
              onClick={() => setIsEditFormName(true)}
            >
              <Icon name="edit" />
            </AsText>
          </>
        )}
      </div>
      <S.Container onKeyDown={handleKeyDown}>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          render={({ handleSubmit, form, values }) => {
            formRef.current = form;

            return (
              <S.PageWrapper onSubmit={handleSubmit}>
                <Observer>
                  {() => (
                    <>
                      <Droppable id="main-board" localStore={localStore} />

                      <DragOverlay dropAnimation={defaultDropAnimation}>
                        {localStore.activeElementIndex !== null && (
                          <BlockItem block={localStore.activeBlock} />
                        )}
                      </DragOverlay>
                    </>
                  )}
                </Observer>
              </S.PageWrapper>
            );
          }}
        />
        <S.ToolsWrapper>
          {TOOLS.map((tool) => (
            <Draggable key={`tool-${tool.id}`} tool={tool} />
          ))}
        </S.ToolsWrapper>
      </S.Container>

      <Confirm
        content="Are you sure you want to pernently remove this project?"
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteForm}
        color="red"
        size="tiny"
      />

      <Confirm
        header="You have changes that not saved"
        content="Are you sure you want to leave?"
        open={showSaveChangesModal}
        onCancel={handleOutWithoutSaving}
        onConfirm={handleStay}
        onClose={handleStay}
        confirmButton="Stay"
        cancelButton="Close without saving"
        size="tiny"
      />

      <DuplicateFormModal
        open={openDuplicateModal}
        setOpen={setOpenDuplicateModal}
        projectId={projectId}
        sourceForm={form}
      />
    </S.PanelWrapper>
  );
});
