import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Enter } from "components/Enter";
import { Dashboard } from "components/Dashboard";
import { ToastContainer } from "react-toastify";

import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-quill/dist/quill.snow.css";
import "./App.css";
import { Website } from "components/Website";
import { Landing } from "components/Landing";
import { ProjectPreview } from "components/Dashboard/Project/ProjectPreview";
import { Payment } from "components/Payment/Payment";
import { ErrorPage } from "components/ErrorPage";
import { Pricing } from "components/Pricing";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/pricing">
            <Pricing />
          </Route>
          <Route exact path="/login">
            <Enter currentScreen="login" />
          </Route>
          <Route exact path="/register">
            <Enter currentScreen="register" />
          </Route>
          <Route exact path="/email-verification">
            <Enter currentScreen="verify" />
          </Route>
          <Route exact path="/reset-password">
            <Enter currentScreen="reset" />
          </Route>
          <Route exact path="/payment/:plan">
            <Payment />
          </Route>
          <Route exact path="/lf/:username/:projectSlug">
            <Website />
          </Route>
          <Route exact path="/dashboard/project/:projectId/preview">
            <ProjectPreview />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>

          {/* change to 404 */}
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
