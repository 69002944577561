import { observer } from "mobx-react-lite";
import { Forms } from "./content/Forms";
import { BuildPart } from "./content/BuildPart";
import { PanelTemplate } from "../PanelTemplate";
import { BUILD_PARTS } from "util/consts";

export const Build = observer(({ lcoalStore, project }) => {
  const pages = [
    {
      name: "Forms",
      value: `${lcoalStore.order.length} forms`,
      className: false,
      content: <Forms lcoalStore={lcoalStore} project={project} />,
    },
    ...Object.values(BUILD_PARTS).map((part) => ({
      name: part.name,
      value: project.settings?.[part.key]?.isActive ? (
        <span className="ls-tag ls-active">Active</span>
      ) : (
        <span className="ls-tag">Not active</span>
      ),
      content: <BuildPart key={part.key} partId={part.key} project={project} />,
    })),
  ];

  return <PanelTemplate pages={pages} />;
});
