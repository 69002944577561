import { Link } from "react-router-dom";
import { Grid, Icon, List } from "semantic-ui-react";

export const Footer = () => {
  return (
    <footer>
      <Grid columns={3} divided>
        <Grid.Row>
          <Grid.Column>
            <List>
              <List.Item>
                <Link to="#privacy-policy">Privacy Policy</Link>
              </List.Item>
              <List.Item>
                <Link to="#terms-of-service">Terms of Service</Link>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List horizontal>
              <List.Item>
                <Link to="#facebook">
                  <Icon name="facebook" />
                </Link>
              </List.Item>
              <List.Item>
                <Link to="#twitter">
                  <Icon name="twitter" />
                </Link>
              </List.Item>
              <List.Item>
                <Link to="#linkedin">
                  <Icon name="linkedin" />
                </Link>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <Link to="#contact-form">Contact Us</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </footer>
  );
};
