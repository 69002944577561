import { Title as TitleElement } from "../Block/Elements/Title";
import { Text as TextElement } from "../Block/Elements/Text";
import { Number as NumberElement } from "../Block/Elements/Number";
import { Email as EmailElement } from "../Block/Elements/Email";
import { TextArea as TextAreaElement } from "../Block/Elements/TextArea";
import { RadioGroup as RadioGroupElement } from "../Block/Elements/RadioGroup";
import { Dropdown as DropdownElement } from "../Block/Elements/Dropdown";
import { Checkbox as CheckboxElement } from "../Block/Elements/Checkbox";
import { Date as DateElement } from "../Block/Elements/Date";
import { Image as ImageElement } from "../Block/Elements/Image";
import { Gallery as GalleryElement } from "../Block/Elements/Gallery";
import { Video as VideoElement } from "../Block/Elements/Video";
import { RichText as RichTextElement } from "../Block/Elements/RichText";
import * as S from "./styles";
import { AsText } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

export const InnerBlock = ({
  type,
  blockId,
  parentLayout,
  localStore,
  defaultValues,
}) => {
  const remove = () => {
    localStore.remove(`block-${blockId}`);
  };

  const initialValues =
    defaultValues?.values?.[defaultValues.originalId]?.[type] || {};

  const innerBlockId = `${parentLayout}.tool-13.items.${blockId}`;

  return (
    <S.Wrapper key={blockId}>
      <S.ButtonOps>
        <AsText type="button" title="remove" onClick={remove}>
          <Icon name="delete" />
          remove
        </AsText>
      </S.ButtonOps>
      <S.Block>
        {type === "empty" && <p>add a new block</p>}
        {type === "tool-1" && (
          <TitleElement blockId={innerBlockId} initialValues={initialValues} />
        )}
        {type === "tool-2" && (
          <TextElement blockId={innerBlockId} initialValues={initialValues} />
        )}
        {type === "tool-3" && (
          <TextAreaElement
            blockId={innerBlockId}
            initialValues={initialValues}
          />
        )}
        {type === "tool-4" && (
          <RadioGroupElement
            blockId={innerBlockId}
            initialValues={initialValues}
          />
        )}

        {type === "tool-5" && (
          <DropdownElement
            blockId={innerBlockId}
            initialValues={initialValues}
          />
        )}

        {type === "tool-6" && (
          <CheckboxElement
            blockId={innerBlockId}
            initialValues={initialValues}
          />
        )}

        {type === "tool-7" && (
          <DateElement blockId={innerBlockId} initialValues={initialValues} />
        )}

        {type === "tool-8" && (
          <EmailElement blockId={innerBlockId} initialValues={initialValues} />
        )}

        {type === "tool-9" && (
          <NumberElement blockId={innerBlockId} initialValues={initialValues} />
        )}

        {type === "tool-10" && (
          <ImageElement blockId={innerBlockId} initialValues={initialValues} />
        )}

        {type === "tool-11" && (
          <GalleryElement
            blockId={innerBlockId}
            initialValues={initialValues}
          />
        )}

        {type === "tool-12" && (
          <VideoElement blockId={innerBlockId} initialValues={initialValues} />
        )}

        {type === "tool-14" && (
          <RichTextElement
            blockId={innerBlockId}
            initialValues={initialValues}
          />
        )}
      </S.Block>
    </S.Wrapper>
  );
};
