import { Title as TitleElement } from "./Title";
import { Text as TextElement } from "./Text";
import { Number as NumberElement } from "./Number";
import { Email as EmailElement } from "./Email";
import { TextArea as TextAreaElement } from "./TextArea";
import { RadioGroup as RadioGroupElement } from "./RadioGroup";
import { Dropdown as DropdownElement } from "./Dropdown";
import { Checkbox as CheckboxElement } from "./Checkbox";
import { DateEl as DateElement } from "./Date";
import { Image as ImageElement } from "./Image";
import { Gallery as GalleryElement } from "./Gallery";
import { Video as VideoElement } from "./Video";
import { RichText as RichTextElement } from "./RichText";
import * as S from "./styles";

export const Layout = ({ blockId, data }) => {
  return (
    <S.LayoutBlock splitTemplate={data.split}>
      {data.items.map((item, idx) => {
        const id = `${blockId}-${idx}`;
        const type = Object.keys(item)[0];
        return (
          <div key={id}>
            <InnerBlock type={type} blockId={id} data={item[type]} />
          </div>
        );
      })}
    </S.LayoutBlock>
  );
};

export const InnerBlock = ({ type, blockId, data }) => {
  if (!data) return null;
  return (
    <div key={blockId}>
      {type === "tool-1" && <TitleElement blockId={blockId} data={data} />}

      {type === "tool-2" && <TextElement blockId={blockId} data={data} />}

      {type === "tool-3" && <TextAreaElement blockId={blockId} data={data} />}

      {type === "tool-4" && <RadioGroupElement blockId={blockId} data={data} />}

      {type === "tool-5" && <DropdownElement blockId={blockId} data={data} />}

      {type === "tool-6" && <CheckboxElement blockId={blockId} data={data} />}

      {type === "tool-7" && <DateElement blockId={blockId} data={data} />}

      {type === "tool-8" && <EmailElement blockId={blockId} data={data} />}

      {type === "tool-9" && <NumberElement blockId={blockId} data={data} />}

      {type === "tool-10" && <ImageElement blockId={blockId} data={data} />}

      {type === "tool-11" && <GalleryElement blockId={blockId} data={data} />}

      {type === "tool-12" && <VideoElement blockId={blockId} data={data} />}

      {type === "tool-14" && <RichTextElement blockId={blockId} data={data} />}
    </div>
  );
};
