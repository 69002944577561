import { Button, Form as SemanticForm, Modal } from "semantic-ui-react";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { FieldText } from "ui/FieldText";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { useStore } from "structure";
import { useHistory } from "react-router-dom";

export const DuplicateProjectModal = observer(
  ({ open, setOpen, projectId }) => {
    const { projectsStore } = useStore();
    const history = useHistory();

    const sourceProject = projectsStore.findProjectById(projectId);

    const onSubmit = async (data) => {
      const newProjectId = await projectsStore.duplicate({
        name: data.name,
        isPublic: data.public,
        public_settings: sourceProject.public_settings,
        settings: sourceProject.settings,
        forms: sourceProject.forms,
      });
      if (newProjectId) {
        setOpen(false);
        history.push(`/dashboard/project/${newProjectId}`);
      }
    };
    return (
      <>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Modal.Header>Duplicate Project</Modal.Header>
          <Modal.Content>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting }) => {
                return (
                  <SemanticForm onSubmit={handleSubmit}>
                    <ul>
                      <li>
                        <FieldText
                          label="Duplicated project name"
                          finalFormProps={{
                            name: "name",
                            initialValue: `${sourceProject.name} - duplicate`,
                          }}
                          semanticProps={{
                            placeholder: "Project name",
                          }}
                        />
                      </li>
                      <li>
                        <FieldCheckbox
                          finalFormProps={{
                            name: "public",
                          }}
                          semanticProps={{
                            label: "public",
                          }}
                          initialChecked={sourceProject.isPublic}
                        />
                      </li>
                    </ul>
                    <Modal.Actions>
                      <Button
                        type="button"
                        basic
                        content="Cancel"
                        disabled={submitting}
                        onClick={() => setOpen(false)}
                      />
                      <Button
                        type="submit"
                        primary
                        content="Duplicate"
                        disabled={submitting}
                        loading={submitting}
                      />
                    </Modal.Actions>
                  </SemanticForm>
                );
              }}
            />
          </Modal.Content>
        </Modal>
      </>
    );
  }
);
