import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import {
  Icon,
  Checkbox,
  Button,
  Dropdown as SemanticDropdown,
} from "semantic-ui-react";
import * as S from "./styles";
import { FieldArray } from "react-final-form-arrays";

const selectionOptions = [
  {
    key: "single",
    text: "Single",
    value: "single",
  },
  {
    key: "multi",
    text: "Multi",
    value: "multi",
  },
];

export const Dropdown = ({ blockId, initialValues }) => {
  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-5.field`}
        initialValue={initialValues.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder="field text"
          />
        )}
      </Field>
      <Field
        name={`${blockId}.tool-5.desc`}
        initialValue={initialValues.desc || ""}
      >
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="description (optional)"
            />
          </S.Field>
        )}
      </Field>
      <Field
        name={`${blockId}.tool-5.selection`}
        initialValue={initialValues.selection || ""}
      >
        {(props) => (
          <S.Field>
            <label>Selection</label>
            <SemanticDropdown
              name={props.input.name}
              value={props.input.value}
              onChange={(e, data) => {
                props.input.onChange(data.value);
              }}
              fluid
              search
              selection
              options={selectionOptions}
            />
          </S.Field>
        )}
      </Field>
      <FieldArray
        name={`${blockId}.tool-5.options`}
        defaultValue={initialValues.options || []}
      >
        {({ fields }) => {
          return (
            <S.DropdownOptions>
              <h4>Options</h4>
              <Button type="button" onClick={() => fields.push("")}>
                + Add option
              </Button>
              {fields.map((name, idx) => (
                <Field key={`${name}-${idx}`} name={name} initialValue={name}>
                  {(props) => (
                    <S.Field className="ls-field">
                      <div className="option-demo">option</div>
                      <TextareaAutosize
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        className="ls-contentEditable"
                        placeholder={`option #${idx + 1}`}
                      />
                      <Button
                        className="remove-option"
                        type="button"
                        onClick={() => fields.remove(idx)}
                      >
                        <Icon name="delete" />
                      </Button>
                    </S.Field>
                  )}
                </Field>
              ))}
            </S.DropdownOptions>
          );
        }}
      </FieldArray>

      <Field name={`${blockId}.tool-5.required`} value={initialValues.required}>
        {(props) => (
          <S.Field>
            <Checkbox
              name={props.input.name}
              checked={props.input.value || false}
              onChange={(e, data) => {
                props.input.onChange(data.checked);
              }}
              label="Required"
            />
          </S.Field>
        )}
      </Field>
    </S.Block>
  );
};
