import { Modal } from "semantic-ui-react";
import * as S from "./styles";
import { Icon } from "assets/icons/Icon";
import { AsText } from "ui/common-styles";
import { useState } from "react";
import { useStore } from "structure";
import { Confirm } from "ui/Confirm";
import { toast } from "react-toastify";

const BASE_URL = "https://sparks-creative-api.brightsite.co.il/";

export const MediaItem = ({
  item,
  handleImageClick,
  selectedImage,
  selectedImages = [],
}) => {
  const { mediaStore } = useStore();
  const [open, setOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  return (
    <>
      <S.MediaItemWrapper
        className={
          selectedImage === item || selectedImages.includes(item)
            ? "ls-active"
            : null
        }
        onClick={() =>
          handleImageClick ? handleImageClick(item) : setOpen(true)
        }
      >
        <img
          src={`${BASE_URL}${item}`}
          alt="media-item"
          style={{
            animation: "fadeIn 0.4s",
          }}
          loading="lazy"
        />
        {!handleImageClick && (
          <div className="media-actions" onClick={(e) => e.stopPropagation()}>
            <AsText onClick={() => setOpen(true)}>
              <Icon name="copy" />
            </AsText>

            <AsText onClick={() => setIsDeleteModalOpen(true)}>
              <Icon name="delete" />
            </AsText>
          </div>
        )}
      </S.MediaItemWrapper>
      <Confirm
        content="Are you sure you want to pernently delete this image?"
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={() =>
          mediaStore
            .removeImage(item)
            .then(() => toast.success("Image deleted successfully"))
        }
        size="tiny"
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeOnDimmerClick
        style={{
          background: "transparent",
          boxShadow: "none",
          width: "auto",
        }}
      >
        <AsText
          onClick={() => setOpen(false)}
          style={{
            position: "absolute",
            right: 0,
            margin: 24,
            padding: 8,
            background: "#ffffffd1",
            borderRadius: 24,
            fill: "black",
            border: "1px solid",
          }}
        >
          <Icon name="close" />
        </AsText>

        <img
          src={`${BASE_URL}${item}`}
          alt="media-full-size"
          style={{
            borderRadius: 24,
            maxHeight: "90vh",
            maxWidth: "90vw",
            animation: "fadeIn 0.4s",
          }}
        />
      </Modal>
    </>
  );
};
