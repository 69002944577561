import styled from "styled-components";
import { scrollElement } from "ui/common-styles";

export const Container = styled.div`
  display: grid;
  grid-template-columns: max(70%, calc(100% - 256px)) min(30%, 232px);
  gap: 24px;
  justify-content: space-around;
  justify-items: center;
  width: 100%;

  .active-drag > div:last-child {
    transition: 0.3s;
    box-shadow: 0px 4px 16px #00000018;
    outline: 1px solid var(--gray-700);
  }
`;

export const ToolsWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0px;
  display: flex;
  padding: 16px;
  gap: 16px;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  background: var(--gray-900);
  border-radius: 12px;

  .tool {
    height: 92px;
    width: 92px;
    margin: 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #66697a;
    border-radius: 8px;
    fill: var(--gray-100);
    p {
      color: var(--gray-100);
      text-transform: capitalize;
      font-family: var(--primary-font);
      font-weight: 300;
      font-size: 0.9em;
      line-height: 1;
    }
    &:hover p {
      color: white;
    }
  }
`;

export const PanelWrapper = styled.div`
  height: 100%;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ls-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      list-style-type: none;
      gap: 16px;
      margin: 0px;
      border-bottom: 1px solid var(--gray-500);
      padding: 0 8px 8px;
      margin-bottom: -8px;
    }
  }
  .ls-form-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    height: 50px;

    > form {
      display: flex;
    }
    h1 {
      font-weight: 700;
      color: var(--primary);
    }
    svg {
      fill: var(--gray-700);
    }
    .ui.input > input {
      font-weight: 700;
      color: var(--primary);
      line-height: 1.3;
      font-size: 2rem;
      padding: 0;
      background: var(--gray-100);
      border: none;
      border-radius: 0;
      border-bottom: 1px solid var(--gray-500);
      margin-bottom: 1rem;
    }

    .ls-form-name-actions {
      display: flex;
      gap: 8px;
      animation: 0.6s fadeIn;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
`;

export const PageWrapper = styled.form`
  height: calc(100% - 14px);
  // width: 600px;
  width: 100%;
  margin-right: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 12px;
  .ls-blocks {
    width: 100%;
    height: calc(80vh - 60px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 8px 16px 16px;

    ${scrollElement}
  }
  .ls-ops {
    height: 60px;
    width: 100%;
    display: flex;
    gap: 16px;
    background: #947676;
    align-items: center;
    justify-content: center;
  }
  .block-drag {
    height: 0;
    button {
      position: relative;
      top: 22px;
      left: 20px;
      background: transparent;
      color: var(--gray-900);
      border-radius: 8px;
      border: none;
      padding: 8px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      cursor: grab;
    }
  }
`;
