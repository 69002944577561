import { FieldCheckbox } from "ui/FieldCheckbox";
import * as S from "./styles";
import { multiValidators, requiredCheck } from "util/validators";

export const Checkbox = ({ blockId, data }) => {
  const validatorsArr = data.required ? [requiredCheck] : [];
  return (
    <S.Block>
      <FieldCheckbox
        finalFormProps={{
          name: blockId,
          validate: multiValidators(validatorsArr),
        }}
        semanticProps={{
          label: data.field,
          ...(data.type === "toggle" ? { toggle: true } : {}),
        }}
      />
    </S.Block>
  );
};
