import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Input, Dropdown, Checkbox } from "semantic-ui-react";
import * as S from "./styles";
import { useState } from "react";

const typeOptions = [
  {
    key: "date",
    text: "date",
    value: "date",
  },
  {
    key: "time",
    text: "time",
    value: "time",
  },
  {
    key: "datetime",
    text: "datetime",
    value: "datetime-local",
  },
];

const dateOptions = [
  {
    key: "none",
    text: "none",
    value: "none",
  },
  {
    key: "current",
    text: "current",
    value: "current",
  },
  {
    key: "spesific",
    text: "spesific",
    value: "spesific",
  },
];

export const Date = ({ blockId, initialValues }) => {
  const [selection, setSelection] = useState({
    type: "date",
    default: false,
    min: false,
    max: false,
  });
  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-7.field`}
        initialValue={initialValues.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder="field text"
          />
        )}
      </Field>
      <Field
        name={`${blockId}.tool-7.desc`}
        initialValue={initialValues.desc || ""}
      >
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="description (optional)"
            />
          </S.Field>
        )}
      </Field>
      <Field
        name={`${blockId}.tool-7.type`}
        initialValue={initialValues.type || "date"}
      >
        {(props) => (
          <S.Field>
            <label>Field type</label>
            <Dropdown
              name={props.input.name}
              value={props.input.value}
              onChange={(e, data) => {
                props.input.onChange(data.value);
                setSelection({ ...selection, type: data.value });
              }}
              fluid
              search
              selection
              options={typeOptions}
            />
          </S.Field>
        )}
      </Field>

      <S.Grid>
        <Field
          name={`${blockId}.tool-7.defaultDate`}
          initialValue={initialValues.defaultDate || "none"}
          className="selectField"
        >
          {(props) => (
            <S.Field>
              <label>Default date</label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  setSelection({
                    ...selection,
                    default: data.value === "spesific",
                  });
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={dateOptions}
              />
            </S.Field>
          )}
        </Field>
        {selection.default && (
          <Field
            name={`${blockId}.tool-7.defaultDatePicker`}
            initialValue={initialValues.defaultDatePicker || ""}
          >
            {(props) => (
              <S.Field className="pickerField">
                <Input
                  type={selection.type}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, data) => {
                    props.input.onChange(data.value);
                  }}
                />
              </S.Field>
            )}
          </Field>
        )}
        <Field
          name={`${blockId}.tool-7.minDate`}
          initialValue={initialValues.minDate || "none"}
        >
          {(props) => (
            <S.Field className="selectField">
              <label>Min date</label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  setSelection({
                    ...selection,
                    min: data.value === "spesific",
                  });
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={dateOptions}
              />
            </S.Field>
          )}
        </Field>
        {selection.min && (
          <Field
            name={`${blockId}.tool-7.minDatePicker`}
            initialValue={initialValues.minDatePicker || ""}
            className="selectField"
          >
            {(props) => (
              <S.Field className="pickerField">
                <Input
                  type={selection.type}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, data) => {
                    props.input.onChange(data.value);
                  }}
                />
              </S.Field>
            )}
          </Field>
        )}
        <Field
          name={`${blockId}.tool-7.maxDate`}
          initialValue={initialValues.maxDate || "none"}
        >
          {(props) => (
            <S.Field className="selectField">
              <label>Max date</label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  setSelection({
                    ...selection,
                    max: data.value === "spesific",
                  });
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={dateOptions}
              />
            </S.Field>
          )}
        </Field>
        {selection.max && (
          <Field
            name={`${blockId}.tool-7.maxDatePicker`}
            initialValue={initialValues.maxDatePicker || ""}
          >
            {(props) => (
              <S.Field className="pickerField">
                <Input
                  type={selection.type}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, data) => {
                    props.input.onChange(data.value);
                  }}
                />
              </S.Field>
            )}
          </Field>
        )}
      </S.Grid>

      <Field name={`${blockId}.tool-7.required`} value={initialValues.required}>
        {(props) => (
          <S.Field>
            <Checkbox
              name={props.input.name}
              checked={props.input.value || false}
              onChange={(e, data) => {
                props.input.onChange(data.checked);
              }}
              label="Required"
            />
          </S.Field>
        )}
      </Field>
    </S.Block>
  );
};
