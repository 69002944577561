import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "assets/icons/Icon";

export const Draggable = ({ tool }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useDraggable({
      id: `tool-${tool.id}`,
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <button
      ref={setNodeRef}
      style={style}
      className="tool"
      alt={tool.desc}
      {...listeners}
      {...attributes}
    >
      <Icon name={tool.icon} size="big" />
      <p>{tool.name}</p>
    </button>
  );
};
