import { Container } from "./styles";

const ERRORS = {
  ["404"]: "Page not found",
};

export const ErrorPage = ({ error = 404 }) => {
  return (
    <Container>
      <h1>{error}</h1>
      <p>{ERRORS[error]}</p>
    </Container>
  );
};
