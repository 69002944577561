import { Button, Form as SemanticForm, Modal } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { FieldText } from "ui/FieldText";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { useStore } from "structure";

export const DuplicateFormModal = observer(
  ({ open, setOpen, projectId, sourceForm }) => {
    const { projectsStore } = useStore();
    const history = useHistory();
    const onSubmit = async (data) => {
      const newForm = await projectsStore.duplicateForm(projectId, {
        ...sourceForm,
        name: data.name,
      });
      setOpen(false);
      history.push(`/dashboard/project/${projectId}/${newForm}`);
    };

    return (
      <>
        <Modal
          size="tiny"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Modal.Header>Duplicate Project</Modal.Header>
          <Modal.Content>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting }) => {
                return (
                  <SemanticForm onSubmit={handleSubmit}>
                    <ul>
                      <li>
                        <FieldText
                          label="Duplicated form name"
                          finalFormProps={{
                            name: "name",
                            initialValue: `${sourceForm.name} - duplicate`,
                          }}
                          semanticProps={{
                            placeholder: "Form name",
                          }}
                        />
                      </li>
                    </ul>
                    <Modal.Actions>
                      <Button
                        type="button"
                        basic
                        content="Cancel"
                        disabled={submitting}
                        onClick={() => setOpen(false)}
                      />
                      <Button
                        type="submit"
                        primary
                        content="Duplicate form"
                        disabled={submitting}
                        loading={submitting}
                      />
                    </Modal.Actions>
                  </SemanticForm>
                );
              }}
            />
          </Modal.Content>
        </Modal>
      </>
    );
  }
);
