import styled from "styled-components";
import { scrollElement } from "ui/common-styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .main-section {
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    h3 {
      font-family: var(--title-font);
      color: var(--primary);
      font-weight: 700;
      font-size: 64px;
    }
  }
`;

export const GalleryWrapper = styled.div`
  background: white;
  border-radius: 16px;
  margin-right: 8px;

  input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
  }

  /* file upload button hover state */
  input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
  }

  /* file upload button active state */
  input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
  }

  .gallery-grid {
    display: flex;
    gap: 24px;
    padding: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 60vh;
    overflow-y: auto;

    ${scrollElement}
  }

  .gallery-upload {
    padding: 16px;
    border-top: 1px solid var(--gray-300);
  }
`;
