import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import * as S from "./styles";
import { useState } from "react";
import { Button, Dropdown, Input } from "semantic-ui-react";
import { MediaGalleryModal } from "components/shared/MediaGalleryModal";
import { Carusel } from "ui/Carusel";

const sizeOptions = [
  {
    key: "default",
    text: "Template Default",
    value: "default",
  },
  {
    key: "px",
    text: "px",
    value: "px",
  },
  {
    key: "percent",
    text: "%",
    value: "percent",
  },
];

const BASE_URL = "https://sparks-creative-api.brightsite.co.il/";

export const Gallery = ({ blockId, initialValues }) => {
  const [selection, setSelection] = useState({
    size: initialValues.size || "default",
    height: initialValues.height || 300,
    width: initialValues.width || 300,
    height_p: initialValues.height_p || 50,
    width_p: initialValues.width_p || 50,
  });

  const updateValue = (field, val) => {
    setSelection({ ...selection, [field]: val });
  };

  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-11.images`}
        initialValue={initialValues.images || []}
      >
        {(props) => (
          <S.Gallery>
            <input
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              type="hidden"
            />

            <Carusel>
              {props.input.value?.map((item, idx) => (
                <img
                  key={idx}
                  alt="gallery-item"
                  src={item}
                  height={
                    selection.size === "percent"
                      ? `${selection.height_p}%`
                      : `${selection.height}px`
                  }
                  width={
                    selection.size === "percent"
                      ? `${selection.width_p}%`
                      : `${selection.width}px`
                  }
                />
              ))}
            </Carusel>
            <MediaGalleryModal
              onSelect={(data) => {
                props.input.onChange(data);
              }}
              multiple
              initialValue={props.input.value.map((item) =>
                item?.replace(BASE_URL, "")
              )}
              trigger={<Button type="button">Edit gallery</Button>}
            />
          </S.Gallery>
        )}
      </Field>
      <S.Grid>
        <Field
          name={`${blockId}.tool-11.desc`}
          initialValue={initialValues.desc || ""}
        >
          {(props) => (
            <S.DescText>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
                placeholder="title here"
              />
            </S.DescText>
          )}
        </Field>
        <div>
          <Field
            name={`${blockId}.tool-11.size`}
            initialValue={selection.size}
            className="selectField"
          >
            {(props) => (
              <S.Field>
                <label>Image size by</label>
                <Dropdown
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, data) => {
                    updateValue("size", data.value);
                    props.input.onChange(data.value);
                  }}
                  fluid
                  selection
                  options={sizeOptions}
                />
              </S.Field>
            )}
          </Field>
          {selection.size !== "default" && (
            <S.Grid style={{ marginTop: 16 }}>
              <Field
                name={`${blockId}.tool-11.height`}
                defaultValue={
                  selection.size === "percent"
                    ? selection.height_p
                    : selection.height
                }
              >
                {(props) => (
                  <S.Field>
                    <label style={{ marginRight: 16 }}>Height</label>
                    <Input
                      type="number"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={(e, data) => {
                        updateValue(
                          selection.size === "percent" ? "height_p" : "height",
                          data.value
                        );
                        props.input.onChange(data.value);
                      }}
                      min={0}
                    />
                  </S.Field>
                )}
              </Field>
              <Field
                name={`${blockId}.tool-11.width`}
                defaultValue={
                  selection.size === "percent"
                    ? selection.width_p
                    : selection.width
                }
              >
                {(props) => (
                  <S.Field>
                    <label style={{ marginRight: 16 }}>Width</label>
                    <Input
                      type="number"
                      name={props.input.name}
                      value={props.input.value}
                      min={0}
                      onChange={(e, data) => {
                        updateValue(
                          selection.size === "percent" ? "width_p" : "width",
                          data.value
                        );
                        props.input.onChange(data.value);
                      }}
                    />
                  </S.Field>
                )}
              </Field>
            </S.Grid>
          )}
        </div>
      </S.Grid>
    </S.Block>
  );
};
