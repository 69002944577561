import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import * as S from "./styles";

export const Title = ({ blockId, initialValues }) => {
  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-1.title`}
        initialValue={initialValues?.title || ""}
      >
        {(props) => (
          <S.TitleText>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="title here"
            />
          </S.TitleText>
        )}
      </Field>
      <Field
        name={`${blockId}.tool-1.desc`}
        initialValue={initialValues.desc || ""}
      >
        {(props) => (
          <S.DescText>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder="title here"
            />
          </S.DescText>
        )}
      </Field>
    </S.Block>
  );
};
