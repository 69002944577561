import { Container } from "./styles";
import { Hero } from "./parts/Hero";
import { Features } from "./parts/Features";
import { Testimonials } from "./parts/Testimonials";
import { Outlet } from "components/Outlet";

export const Landing = () => {
  return (
    <Container>
      <Outlet>
        <Hero />
        <Features />
        <Testimonials />
      </Outlet>
    </Container>
  );
};
