import { observer } from "mobx-react-lite";
import * as S from "./styles";
import { useEffect, useRef, useState } from "react"; // Import useState
import { useStore } from "structure";
import { Form } from "react-final-form";
import { Button } from "semantic-ui-react";
import { MediaItem } from "components/shared/MediaGalleryModal/MediaItem";
import { toast } from "react-toastify";

const BASE_URL = "https://sparks-creative-api.brightsite.co.il/";

export const MediaGallery = observer(({ onSelect, selected, onCancel }) => {
  const { mediaStore } = useStore();
  let inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(selected || null);

  const handleImageClick = (item) => {
    setSelectedImage(item);
  };

  useEffect(() => {
    mediaStore.fetchMedia();
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    if (selectedFile) {
      mediaStore
        .addImage(selectedFile)
        .then(() => {
          toast.success("Image uploaded successfully");
          inputRef.current.value = null;
          setSelectedFile(null);
        })
        .catch((error) => {
          toast.success("Error accured while trying to upload the image");
          console.error("Error uploading file:", error);
        });
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Set the selected file in state
  };

  const gallery = mediaStore?.media?.images || [];

  return (
    <S.GalleryWrapper>
      <div className="gallery-grid">
        {gallery.map((item) => (
          <MediaItem
            key={item}
            item={item}
            handleImageClick={onSelect ? handleImageClick : null}
            selectedImage={selectedImage}
          />
        ))}
      </div>

      <div className="gallery-upload">
        {!!onSelect ? (
          <div style={{ float: "right", paddingBottom: 16 }}>
            <Button
              basic
              content="Cancel"
              onClick={onCancel}
              style={{ marginRight: 16 }}
            />
            <Button
              onClick={() => onSelect(`${BASE_URL}${selectedImage}`)}
              primary
              content="Select"
              disabled={!selectedImage}
            />
          </div>
        ) : (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <input
                    ref={inputRef}
                    type="file"
                    name="image"
                    onChange={handleFileChange}
                  />
                  <Button
                    type="submit"
                    primary
                    content="Upload"
                    disabled={!selectedFile}
                  />
                </form>
              );
            }}
          />
        )}
      </div>
    </S.GalleryWrapper>
  );
});
