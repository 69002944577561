import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <header>
      <Link to="/">
        <img src="/logo.png" alt="Logo" />
      </Link>
      <nav>
        <Link to="/product">Product</Link>
        <Link to="/solutions">Solutions</Link>
        <Link to="/pricing">Pricing</Link>
      </nav>
      <div>
        <Link className="ls-login" to="/login">
          Login
        </Link>
        <Link className="ls-signup" to="/register">
          Start for free
        </Link>
      </div>
    </header>
  );
};
