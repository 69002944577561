import styled from "styled-components";

export const Container = styled.div`
position: fixed;
top: 24px;
right: 24px;
    width: calc(100% - 324px);
    padding: 24px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(8px);
    box-shadow: 0px 4px 16px rgba(25, 26, 33, 0.05);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    z-index: 1;

    .ui.search {
        input {
            background: var(--primary-bright-off);
            border: none;
            &::placeholder {
                color: var(--gray-700);
            }
        }
        &.focus input {
                background: var(--primary-bright);
                &::placeholder {
                    color: var(--gray-900);
                }
            }
        }
    }
`;

export const Wrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }

  > .ui.dropdown {
    display: flex;
    align-items: center;

    .username-btn {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--primary-dark);
      border-bottom: 1px solid transparent;
      transition: border-color 0.4s;

      svg {
        height: 32px;
        width: 32px;
        fill: var(--primary);
      }

      span {
        font-size: 18px;
        text-transform: capitalize;
      }

      :hover {
        border-color: var(--primary);
      }
    }

    > .menu {
      min-width: 160px;

      .item {
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
          height: 24px;
          width: 24px;
        }

        span {
          font-size: 16px;
          text-transform: capitalize;
        }
      }
    }
  }
`;

export const Avatar = styled.span`
  text-transform: uppercase;
  background: rgb(255 199 113);
  background: linear-gradient(
    17deg,
    rgb(255 199 113) 0%,
    rgb(255 187 225) 100%
  );
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  border-radius: 100%;
  margin: 0;
`;

export const DropdownButton = styled.button`
  padding: 0;
  border: 0px;
  font-family: inherit;
  background: transparent;
  color: inherit;
  width: 100%;
`;
