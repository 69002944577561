import { Icon } from "assets/icons/Icon";
import { Content } from "components/Website/Content";
import { Loading } from "components/shared/Loading";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useStore } from "structure";
import { AsText } from "ui/common-styles";
import * as S from "./styles";

export const ProjectPreview = observer(() => {
  const { projectsStore } = useStore();
  let { projectId } = useParams();
  const project = projectsStore.findProjectById(projectId);
  const data = {
    ...project,
    settings: project?.public_settings,
  };

  useEffect(() => {
    if (!project) {
      projectsStore.fetchProjects();
    }
    // eslint-disable-next-line
  }, []);

  if (!project || projectsStore.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <S.PreviewHeader>
        <AsText as={Link} to={`/dashboard/project/${projectId}`} color="black">
          <Icon name="back" />
          back to project managment
        </AsText>
        <p>Preview project</p>
      </S.PreviewHeader>

      <Content data={data} />
    </div>
  );
});
