import { Radio } from "semantic-ui-react";
import * as S from "./styles";
import { Field } from "react-final-form";
import { useState } from "react";
import { FormField } from "ui/common-styles";
import { multiValidators, required } from "util/validators";

export const RadioGroup = ({ blockId, data }) => {
  const [value, setValue] = useState(null);
  if (!data.options) return null;

  const validatorsArr = data.required ? [required] : [];

  return (
    <S.Block>
      {data.field && <p className="lf-field">{data.field}</p>}
      {data.desc && <p>{data.desc}</p>}
      <Field
        name={`${blockId}`}
        value={value}
        validate={multiValidators(validatorsArr)}
      >
        {({ input, meta }) => {
          return (
            <FormField>
              {data.options.map((option, idx) => (
                <div key={idx}>
                  <Radio
                    label={option}
                    name={`${blockId}`}
                    value={option}
                    checked={value === option}
                    onClick={() => {
                      setValue(option);
                      input.onChange(option);
                    }}
                  />
                </div>
              ))}
              {meta.touched && meta.error && (
                <p className="ls-error" style={{ paddingLeft: 26 }}>
                  {meta.error}
                </p>
              )}
            </FormField>
          );
        }}
      </Field>
    </S.Block>
  );
};
